import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import './LoginForm.css';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CryptoJS from 'crypto-js';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  FormControlLabel,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  Typography,
  Alert,
  Fade
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Import APi to login
import { BankWiseUrl } from '../../../services/globalApi';
import { userDataFromLocal } from '../../../utils/getUserDetails';

const LoginApiUrl = `${BankWiseUrl.globalKnowledgeGraphUrl}/visual/userlogin`;

function LoginForm() {
  const navigate = useNavigate();
  // const { GLOBAL_PATH } = getGlobals();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  // function
  const firedGetToken = (formValue, formikSubmit, formik) => {
    setisSubmitting(true);
    console.log('formikSubmit', formikSubmit);
    // const formData = new FormData();
    // formData.append('username', formValue.email.split('@')[0]);
    // formData.append('password', formValue.password);
    // LoginApiUrl
    const loginData = {
      username: formValue.email,
      password: formValue.password
    };
    // console.log('selectedURLObject', selectedURLObject);
    async function firePostFormDataApi(LoginApiUrl, formData) {
      return fetch(LoginApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // Authorization: authToken
        },
        body: JSON.stringify(loginData)
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // throw new Error('Bad Credential');
            setErrorMsg(true);
            setTimeout(() => {
              setisSubmitting(false);
            }, 2000);
          }
        })
        .then((result) => {
          console.log('result', result);
          if (result.status === 'success') {
            console.log('result', result);
            const userLoginDetails = {
              isLogedIn: true,
              // userName: formValue.email.split('@')[0],
              userName: result.data.username,
              userID: result.data.user_id,
              token: result.data.access_token,
              modules: result.data?.modules
              // Access: result.tokens.authentication.data.access,
              // Refresh: result.tokens.authentication.data.refresh
              // allTokens: result.data?.tokens
            };
            // console.log('result', userLoginDetails?.allTokens);
            const userData = CryptoJS.AES.encrypt(JSON.stringify(userLoginDetails), 'userDeatils');
            localStorage.setItem('userDeatils', userData);
            // setTimeout(() => navigate(`${GLOBAL_PATH}/${navigateKey}`, { replace: true }), 500);
            // setTimeout(() => navigate(`${GLOBAL_PATH}/dashboard`), 500);
            setTimeout(() => navigate(`/knowledgegraph`), 500);
          } else {
            // alert('tst');
            console.log('result', 'in error');
            setErrorMsg(true);
            setisSubmitting(false);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    firePostFormDataApi(LoginApiUrl, loginData);
  };

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    email: Yup.string().required('User name is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      // console.log('formik.values', formik.values);
      // localStorage.setItem(
      //   'userDeatils',
      //   JSON.stringify({
      //     isLogedIn: true,
      //     userName: formik.values.email.split('@')[0]
      //   })
      // );
      firedGetToken(formik.values, formik.isSubmitting, formik);
      // setTimeout(() => navigate('/', { replace: true }), 1000);
      // navigate('/alerts');
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  /**
   * To handing show password button
   */
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  useEffect(() => {
    console.log('userDataFromLocal', userDataFromLocal());
    const fetchUserDetailsLocalStorage = localStorage.getItem('userDeatils');
    // Below given code to prevant back and forth page
    if (fetchUserDetailsLocalStorage && userDataFromLocal().isLogedIn === true) {
      console.log('entering fetchUserDetailsLocalStorage');

      setTimeout(() => navigate(`/knowledgegraph`), 500);
    } else {
      localStorage.clear(); // first time clear local storage. Blocking previous page.
    }
  }, []);

  // Dynamic copyright year
  const year = new Date();
  const getYear = year.getFullYear();

  // handlechange
  const [choice, setChoice] = useState(true);
  const handleChange = () => {
    setErrorMsg(false);
    setChoice(!choice);
  };

  return (
    <Box className="LoginOuter" sx={{ width: '100%', height: '100vh' }}>
      <Container
        maxWidth="fullWidth"
        // disableGutters="true"
        sx={{ paddingLeft: 0, paddingRight: 0 }}
        className="frontLogin"
      >
        <Stack>
          <Grid
            container
            className="loginInner loginFrmTxt"
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={6} container justifyContent="center" alignContent="center">
              <Grid sx={{ background: 'white' }} item xs={11} sm={9} md={7}>
                <Box className="customLogWrap" p={4} sx={{ boxShadow: '0px 1px 6px #dcdbdb' }}>
                  {/* <Box className="loginTitle" textAlign="center" justifyContent="center" mb={2}>
                  <Typography component="img" src="../../static/logos/logo.svg" />
                </Box> */}
                  <Typography variant="h4" className="loginTitle" color="#222">
                    {/* Customer Data Platform */}
                    XYLOGIC KNOWLEDGE GRAPH
                  </Typography>
                  <Typography variant="body2" className="loginSubTitle">
                    by Kalolytic
                  </Typography>
                  {errorMsg === true ? (
                    <Fade in={true}>
                      <Stack id="Error" sx={{ width: '100%' }} spacing={1} mb={2}>
                        <Alert severity="error">
                          No active account found below given credentials
                        </Alert>
                      </Stack>
                    </Fade>
                  ) : (
                    ''
                  )}
                  <Typography variant="h5" className="mb-1" color="#222">
                    Sign In
                  </Typography>
                  <Typography variant="body2" className="mb-3">
                    Enter your credentials
                  </Typography>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <FormControl fullWidth variant="standard" className="formGroupLogin mb-2">
                        <MailOutlineIcon className="formControlIcon" />
                        <TextField
                          fullWidth
                          inputProps={{
                            'aria-label': 'password',
                            className: 'formControlFull formControlFullP'
                          }}
                          onClick={handleChange}
                          placeholder="Email"
                          autoComplete="username"
                          type="email"
                          // label="Email address"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </FormControl>
                      <FormControl fullWidth variant="standard" className="formGroupLogin mb-2">
                        <LockOpenIcon className="formControlIcon" />
                        <TextField
                          fullWidth
                          inputProps={{
                            'aria-label': 'password',
                            className: 'formControlFull formControlFullP'
                          }}
                          onClick={handleChange}
                          placeholder="Password"
                          autoComplete="current-password"
                          type={showPassword ? 'text' : 'password'}
                          {...getFieldProps('password')}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                        />
                      </FormControl>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '1rem'
                        }}
                      >
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox {...getFieldProps('remember')} checked={values.remember} />
                            }
                            label="Remember me"
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="" className="mr-1">
                            <Link
                              component={RouterLink}
                              variant="body2"
                              to="#"
                              className="loginFrmTxt"
                            >
                              Forgot password?
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>

                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="button"
                        // loading={isSubmitting}
                        className="loginBtn mb-2"
                      >
                        Login
                      </LoadingButton>
                    </Form>
                  </FormikProvider>
                  <Divider className="mb-2" />
                  <Typography variant="body1" sx={{ textAlign: 'center' }} className="loginFrmTxt">
                    Copyright © {getYear} &nbsp;
                    <a
                      href="http://kalolytic.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="loginFrmTxt"
                    >
                      Kalolytic
                    </a>
                    . All rights reserved.
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{validationMsg}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Retry</Button>
              </DialogActions>
            </Dialog> */}
          </Grid>
        </Stack>
      </Container>
    </Box>

    // <FormikProvider value={formik}>
    //   <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
    //     <Stack spacing={3}>
    //       <TextField
    //         fullWidth
    //         className="gbleinp LoginFrm"
    //         autoComplete="username"
    //         type="email"
    //         label="Email address"
    //         {...getFieldProps('email')}
    //         error={Boolean(touched.email && errors.email)}
    //         helperText={touched.email && errors.email}
    //       />

    //       <TextField
    //         fullWidth
    //         className="gbleinp LoginFrm"
    //         autoComplete="current-password"
    //         type={showPassword ? 'text' : 'password'}
    //         label="Password"
    //         {...getFieldProps('password')}
    //         InputProps={{
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <IconButton onClick={handleShowPassword} edge="end">
    //                 <Icon icon={showPassword ? eyeFill : eyeOffFill} />
    //               </IconButton>
    //             </InputAdornment>
    //           )
    //         }}
    //         error={Boolean(touched.password && errors.password)}
    //         helperText={touched.password && errors.password}
    //       />
    //     </Stack>

    //     <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
    //       <FormControlLabel
    //         control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
    //         label="Remember me"
    //       />

    //       <Link component={RouterLink} variant="subtitle2" to="#">
    //         Forgot password?
    //       </Link>
    //     </Stack>

    //     <LoadingButton
    //       fullWidth
    //       size="large"
    //       type="submit"
    //       variant="contained"
    //       loading={isSubmitting}
    //     >
    //       Login
    //     </LoadingButton>
    //   </Form>
    // </FormikProvider>
  );
}
export default LoginForm;
