import {
  Close,
  Delete,
  Edit,
  ExitToApp,
  ExpandMore,
  Refresh,
  Search,
  Timeline,
  Visibility
} from '@material-ui/icons';
import {
  Spoke,
  Close as MuiCloseIcon,
  Workspaces,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Snackbar,
  ButtonGroup,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SwipeableDrawer,
  TextField,
  Typography,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Avatar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Checkbox,
  Tooltip
} from '@mui/material';
import Slide from '@material-ui/core/Slide';
// import TransitionProps from '@material-ui/transitions';
import Modal from '@mui/material/Modal';
import { styled, alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import Cytoscape from 'cytoscape';
import contextMenus from 'cytoscape-context-menus';
import coseBilkent from 'cytoscape-cose-bilkent';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import CytoscapeComponent from 'react-cytoscapejs';
import { FaCog } from 'react-icons/fa';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// State management
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseIcon,
  DoubleArrowIcon,
  JourneyIcon,
  ModelIcon,
  UserIcon,
  getIcon,
  groupChainIcon
} from '../../../vector/index';
// import GraphTabs from './GraphTabs';
import RenderIcons from '../../global/RenderIcons';
import Page from '../Page';
import EdgeStyleSetting from './EdgeStyleSetting';
import NodeStyleSetting from './NodeStyleSetting';
import subMenuSVG from '../../../images/submenu-indicator-default.svg';

// services
import KnowledgeGraphService from '../../../services/KnowledgeGraphService';
import KnowledgeGraphModelComp from './KnowledgeGraphModelComp';
// import cytoscape-context-menus CSS
import 'cytoscape-context-menus/cytoscape-context-menus.css';
import { camlecaseAndUnderScore } from '../../../utils/camleCaseAndUScroe';
import { fDateTime } from '../../../utils/formatTime';
import BottomTabs from './BottomTabs';
import CanvasDisplayData from './CanvasDisplayData';
import OptionsPlate from './OptionsPlate';
// import SearchListing from './SearchListing';
// import SearchNodesAndEdges from './SearchNodesAndEdges';
// import RuleEngineQuery from './RuleEngineQuery';
import RuleEngineQuery from './RuleEngineQuery';
import DataMap from './DataMap';
import LoaderOpaque from './LoaderOpaque';
import LoaderBlur from './LoaderBlur';
import ApiErrorDialog from './ApiErrorDialog';
// import CustomDataGrid from './CustomDataGrid';
import MyDataGrid from './MyDataGrid';
import TimelineGraph from './TimelineGraph';
import BingMap from './BingMap';
import { userDataFromLocal } from '../../../utils/getUserDetails';

import ValidationMsgOnly from '../../global/ValidationMsgOnly';

// Use APIs
const { GetElements, GetApiByUrl, PostApiByUrl, PatchApiByUrl, DeleteApiByUrl } =
  KnowledgeGraphService;
const expandCollapse = require('cytoscape-expand-collapse'); // cytoscape-expand-collapse

expandCollapse(Cytoscape); // register extension //
// register extension
Cytoscape.use(contextMenus);
Cytoscape.use(coseBilkent);
const Data = require('./dataforgraph.json');

// console.log(Data.data);
const DataObject = Data.data;
// console.log(DataObject);

/* eslint prefer-object-spread: "off" */
const getOrgPos = (n) => {
  return Object.assign({}, n.data('position'));
};

const getOrgPosCol = (n) => {
  // console.log('positions222', n.data('position'));
  return Object.assign({}, n.data('position'));
};

function Visualization() {
  const { cytoscapeComponentRef, searchBarRef } = useRef();
  const dispatch = useDispatch(); // Using React Redux useSelector and useDispatch hook for get and set states values into store
  const {
    selectedGraphTabValue,
    cytoDataAllGraph,
    kgUpdate,
    snackbarToggle,
    slectedOption,
    currentNodeid,
    currentNodeInfo,
    hideSideBar,
    hideSideBarRight,
    expanded,
    currentEdgeData
  } = useSelector((state) => state.knowledgeGraph.allSelectedData);
  const [openValidation, setOpenValidation] = useState(false);
  const [validationMsg, setValidationMsg] = useState('');

  const handleClose = () => {
    setOpenValidation(false);
  };

  useEffect(() => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'expanded',
        value: 'panel5'
      }
    });
  }, []);

  // const [snackPack, setSnackPack] = useState([]);
  // const [snackbarToggle, setSnackbarToggle] = useState(true);
  // const [messageInfo, setMessageInfo] = useState(undefined);

  // useEffect(() => {
  //   if (snackPack.length && !messageInfo) {
  //     // Set a new snack when we don't have an active one
  //     setMessageInfo({ ...snackPack[0] });
  //     setSnackPack((prev) => prev.slice(1));
  //     setOpen(true);
  //   } else if (snackPack.length && messageInfo && open) {
  //     // Close an active snack when a new one is added
  //     setOpen(false);
  //   }
  // }, [snackPack, messageInfo, open]);

  // const handleClick = (message) => () => {
  //   setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  // };

  const layOutName = 'euler';

  const groupBtnStyle = {
    flex: 1,
    fontSize: '0.750rem',
    paddingRight: '9px',
    paddingLeft: '9px',
    background: '#f5f8fc',
    border: '1px solid #ddd',
    color: 'black',
    '&:hover': { color: 'white' }
  };

  const groupBtnStyleBdr = { borderColor: '#C4CDD5' };

  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    const toggleSnackBar = { trigger: false, message: '' };

    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'snackbarToggle',
        value: toggleSnackBar
      }
    });
  };

  // const handleExited = () => {
  //   setMessageInfo(undefined);
  // };

  // const {
  //   allSelectedData: { refreshComponent, currentActiveStep },
  //   createKnowledgeGraph: { styling }
  // } = knowledgeGraphStoreItem;

  const [labelToDisplay, setLabelToDisplay] = useState([]);
  const [labelEdgeToDisplay, setLabelEdgeToDisplay] = useState([]);
  console.log('labelEdgeToDisplay', labelEdgeToDisplay);

  const [searchInput, setSearchInput] = useState('');
  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleExpanedPanel = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false);
  };

  const [tabValue, setTabValue] = useState(0);
  const [subTabValue, setSubTabValue] = useState(0);
  const navigate = useNavigate();
  // console.log(useLocation());
  const elementReference = useRef();

  // const drawFlowRef = useRef();
  // const drawflowReference = useRef();

  const { enqueueSnackbar } = useSnackbar();
  const { experimentId, modelId } = useParams();
  const [loader, setLoader] = useState({ mainLoader: false, switchModels: false });
  const [cytoData, setCytoData] = useState([]);
  const [displayCytoData, setDisplayCytoData] = useState([]);

  // const [currentNodeid, setNodeId] = useState('');
  // const [currentEdgeData, setEdgeData] = useState({});
  const [cyToState, setCyToState] = useState(null);
  const [currentmetaData, setCurrentmetaData] = useState({});
  const [currentMetaDataId, setCurrentMetaDataId] = useState(null);
  const [apiLoader, setApiLoader] = useState(false);
  // console.log('cyToStatecyToState', cyToState);
  // data source column data
  const [responseColoumData, setResponseColoumData] = useState([
    'satisfaction_level',
    'last_evaluation',
    'number_project',
    'average_montly_hours',
    'time_spend_company',
    'Work_accident',
    'promotion_last_5years',
    'department',
    'salary',
    'left'
  ]);
  const [nodeSelect, setNodeSelect] = useState({
    category: 'Messaging',
    country: 'USA',
    first_funding: '01/10/2009',
    founded_at: '01/10/2009',
    founded_monthly: '2009-01',
    founded_quarterly: '2009-01',
    founded_year: 2009,
    funding_round: 3,
    funding_total: 58250000,
    last_funding: '01/07/2003'
  });

  const [graphStyling, setStyling] = useState([
    // the stylesheet for the graph
    {
      selector: 'node',
      style: {
        label: function (node) {
          if (node.data('labelType')) {
            // console.log('founded_quarterly', node.data('labelType'));
            return node.data('labelType');
          }
          return node.data('label');
        },
        'background-color': function (node) {
          if (node.data('label') === 'company') {
            return 'rgb(87, 199, 227)';
          }
          if (node.data('label') === 'directors') {
            if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
              return 'red';
            }
            return 'rgb(141, 204, 147)';
          }

          // rgb(141, 204, 147)
          // use function to set dynamic label
          // console.log('node.isBlacklisted', node.data('props').isBlacklisted);
          if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
            return 'red';
          }
          if (node.data('label') === 'blackDirector') {
            return 'red';
          }
          return '#056be2';
        },
        height: 120,
        width: 120,
        padding: 0,
        'background-fit': 'cover',
        'background-opacity': 1,
        'border-width': 4,
        'border-style': 'solid',
        'border-color': '#000',
        'border-opacity': 1,
        shape: 'ellipse',
        'background-repeat': 'no-repeat',
        'background-image': [],
        'background-image-opacity': 1,
        'font-size': 11,
        color: function (node) {
          // use function to set dynamic label
          if (node.data('props') && node.data('props').isBlacklisted === 'yes') {
            return '#fff';
          }
          if (node.data('label') === 'blackDirector') {
            return '#fff';
          }
          return '#000';
        },
        'text-valign': 'center',
        'text-halign': 'center',
        'text-opacity': 1
      }
    },
    {
      selector: 'edge',
      style: {
        label: 'data(label)',
        'curve-style': 'unbundled-bezier',
        'pointer-events': 'auto',
        'target-arrow-shape': 'triangle',
        'edge-text-rotation': 'autorotate',
        'font-size': 12,
        'text-margin-y': '-7px',
        'text-margin-x': '7px',
        'target-text-margin-y': '-7px',
        'line-color': function (node) {
          if (node.data('label') === 'is_blacklisted') {
            return 'red';
          }
          return '#ddd';
        },
        'line-style': 'solid',
        width: 5,
        color: function (node) {
          if (node.data('label') === 'is_blacklisted') {
            return 'red';
          }
          return '#000';
        },
        'text-opacity': 1
        // "text-margin-x":"10px"
      }
    }
  ]);

  const [commonStyle, setCommonStyle] = useState([
    // the stylesheet for the graph
    {
      selector: 'node',
      style: {
        label: function (node) {
          if (node.data('labelType')) {
            // console.log('founded_quarterly', node.data('labelType'));
            return node.data('labelType');
          }
          return node.data('label');
        }
      }
    },
    {
      selector: 'node[Isblacklisted = "yes"]',
      style: {
        'background-color': 'black'
      }
    },
    {
      selector: 'node.activeNode',
      style: {
        opacity: 0.8,
        'border-color': 'rgb(253, 204, 89)',
        'border-width': 15,
        'border-opacity': 1
      }
    },
    {
      selector: 'node.c_Target',
      style: {
        opacity: 1,
        'border-color': '#0F0F0F',
        'border-width': 15,
        'border-opacity': 1
      }
    },
    {
      selector: 'node.c_Source',
      style: {
        opacity: 1,
        'border-color': 'red',
        'border-width': 15,
        'border-opacity': 1
      }
    },
    {
      selector: 'node[nodeType = "child"]',
      style: {
        display: 'none'
      }
    },
    {
      selector: 'node.highlighted',
      style: {
        'min-zoomed-font-size': 0,
        'z-index': 9999
      }
    },
    {
      selector: 'node.hilightedNode',
      style: {
        events: 'no',
        opacity: '1!important'
      }
    },
    {
      selector: 'node.faded',
      style: {
        events: 'no',
        opacity: 0.1
      }
    },
    {
      selector: 'edge.faded',
      style: {
        events: 'no',
        opacity: 0.1
      }
    },
    {
      selector: 'edge.activeNode',
      style: {
        'line-color': 'rgb(253, 204, 89)',
        'target-arrow-color': 'black',
        width: '8px',
        'z-index': 9999
      }
    },
    {
      selector: 'edge.c_Target',
      style: {
        opacity: 1,
        'line-color': '#0F0F0F',
        'target-arrow-color': '#c29a3e',
        'z-index': 9999
      }
    },
    {
      selector: 'edge.c_Source',
      style: {
        opacity: 1,
        'line-color': 'red',
        'target-arrow-color': '#c29a3e',
        'z-index': 9999
      }
    },
    {
      selector: 'node.visible',
      style: {
        opacity: 1,
        display: 'block'
      }
    },
    {
      selector: `node:selected`,
      style: {
        'border-width': '10px',
        'border-color': 'rgb(253, 204, 89)',
        'border-opacity': 1
      }
    },
    {
      selector: `edge:selected`,
      style: {
        width: '10px',
        'line-color': 'rgb(253, 204, 89)',
        'target-arrow-color': 'rgb(253, 160, 60)'
      }
    },
    {
      selector: 'edge',
      style: {
        'curve-style': 'unbundled-bezier'
      }
    },
    {
      selector: 'node.blacklisted',
      style: {
        'background-color': 'red',
        'border-color': 'black',
        'border-width': 2,
        'border-opacity': 1,
        'background-opacity': 1
      }
    }
  ]);
  // const [hideSideBar, setHideSideBar] = useState('');
  // const [slectedOption, setSlectedOption] = useState('');
  // const [expanded, setExpanded] = useState('');

  const [expandedTrue, setExpandedTrue] = useState('panel1');
  const [refreshKGComp, setRefreshKGComp] = useState(null);
  const [recallModelNodes, setrecallModelNodes] = useState(null);
  const [recallNode, setRecallNode] = useState(null);
  // const [state, setState] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [allNodeForInfo, setAllNodeForInfo] = useState([]);
  const [currentprojectData, setCurrentprojectData] = useState({});
  // console.log(currentprojectData, 'modelcounttotal');

  const [isEdgePropertyModel, openEdgePropertyModel] = useState(null);
  // const [currentNodeInfo, setCurrentNodeInfo] = useState({});

  const [selectedGlobalRules, setSelectedGlobalRules] = useState([]);
  console.log(selectedGlobalRules, 'selectedGlobalRules');

  // const handleChange = (panel) => (event, newExpanded) => {
  //   // console.log('panelPanel', panel, newExpanded);
  //   setExpanded(newExpanded ? panel : false);
  // };

  const handleChange = (panel) => (event, newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'expanded',
        value: newValue ? panel : false
      }
    });
  };

  const saveMetadataChanges = async (currentmetaData) => {
    if (currentmetaData.metadata && currentmetaData.metadata[0]) {
      console.log('currentmetaData', currentmetaData.metadata);
      //  setLoader((prevLoader) => ({ ...prevLoader, mainLoader: true }));
      const stylingArray = [];

      const {
        project_id_id,
        link_id_id,
        meta_id,
        others: { savedDatasetId, stylying }
      } = currentmetaData.metadata[0];

      console.log('currentmetaData', stylying);
      console.log('currentmetaData', cyToState);
      if (cyToState) {
        cyToState.elements().forEach((iten) => {
          const nodeObj = {
            selector: `node[label='${iten.style().label}']`
          };
          stylying.forEach((styleObj) => {
            console.log('styleObj', nodeObj);
            console.log('styleObj', styleObj);
            console.log('styleObj', styleObj);
            console.log('styleObj', iten.style().label);
          });
          const nodeStyleObj = {};
          // Object.keys(allNodesSettings).forEach((key) => (nodeStyleObj[key] = iten.style()[key]));
          // console.log('iten.style()', nodeStyleObj);
          nodeObj.style = nodeStyleObj;
          stylingArray.push(nodeObj);
        });
        const graphMetaData = [];
        cyToState.elements().forEach((iten) => graphMetaData.push(iten.data()));
        const metaCred = {
          meta_id: meta_id,
          project_id: project_id_id,
          link_id: link_id_id,
          nodes_metadata: graphMetaData,
          others: {
            savedDatasetId: savedDatasetId,
            stylying: stylingArray
          }
        };

        console.log('currentmetaData', metaCred);
        // const saveMetaData = await PatchApiByUrl('/visual/manage_metadata', metaCred);
      }
    }
  };

  // function for show and hide when mouse over
  // const showHideNode = (e) => {
  //   const MouseEventX = e.clientX;
  //   const MouseEventY = e.clientY;
  //   // if (MouseEventY < 30) {
  //   //   setHoverState(true);
  //   // } else {
  //   //   setHoverState(false);w
  //   // }
  // };
  // Right click menu on graph
  const [graphRightClick, setGraphRightClick] = useState({
    // Customize event to bring up the context menu
    // Possible options https://js.cytoscape.org/#events/user-input-device-events
    evtType: 'cxttap',
    // List of initial menu items
    // A menu item must have either onClickFunction or submenu or both
    menuItems: [
      // {
      //   id: 'add-node',
      //   content:
      //     '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" t="1569683586275" viewBox="0 0 1024 1024" version="1.1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M672 432c-120.3 0-219.9 88.5-237.3 204H320c-15.5 0-28-12.5-28-28V244h291c14.2 35.2 48.7 60 89 60 53 0 96-43 96-96s-43-96-96-96c-40.3 0-74.8 24.8-89 60H112v72h108v364c0 55.2 44.8 100 100 100h114.7c17.4 115.5 117 204 237.3 204 132.5 0 240-107.5 240-240S804.5 432 672 432z m128 266c0 4.4-3.6 8-8 8h-86v86c0 4.4-3.6 8-8 8h-52c-4.4 0-8-3.6-8-8v-86h-86c-4.4 0-8-3.6-8-8v-52c0-4.4 3.6-8 8-8h86v-86c0-4.4 3.6-8 8-8h52c4.4 0 8 3.6 8 8v86h86c4.4 0 8 3.6 8 8v52z"></path></svg> Create new node</div>',
      //   tooltipText: 'add node',
      //   selector: 'node',
      //   coreAsWell: true,
      //   onClickFunction: function () {
      //     // console.log('add node');
      //     // setOpenDialog(true);
      //   }
      // },
      // {
      //   id: 'add-edge',
      //   content:
      //     '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11 4a4 4 0 1 0 0 8 4 4 0 0 0 0-8zM6.025 7.5a5 5 0 1 1 0 1H4A1.5 1.5 0 0 1 2.5 10h-1A1.5 1.5 0 0 1 0 8.5v-1A1.5 1.5 0 0 1 1.5 6h1A1.5 1.5 0 0 1 4 7.5h2.025zM11 5a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 11 5zM1.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"></path></svg> Create new edge</div>',
      //   tooltipText: 'add edge',
      //   selector: 'edge',
      //   coreAsWell: true,
      //   onClickFunction: function () {
      //     // console.log('add node');
      //   }
      // },
      {
        id: 'black_list',
        content: 'BlackList',
        tooltipText: 'blacklist',
        // coreAsWell: true,
        // hasTrailingDivider: true,
        selector: 'node',
        // onClickFunction: function (event) {
        //   const target = event.target || event.cyTarget;
        //   target.style({ 'background-color': 'red', 'background-opacity': 1 });
        // },
        submenu: [
          {
            id: 'add_blck',
            content: 'Mark as Blacklist',
            tooltipText: 'add blacklist',
            onClickFunction: async function (event) {
              const target = event.target || event.cyTarget;

              if (!target.hasClass('blacklisted')) {
                console.log('saveBlacklistNodes node data', event.target.data());

                const apiPayload = {
                  link_id: Number(modelId),
                  project_id: Number(experimentId),
                  black_nodes_data: {
                    [event.target.data().label]: [Number(event.target.data().id)]
                  }
                };
                const saveBlacklistNodes = await PostApiByUrl(
                  '/visual/neo4j/blacklist_nodes',
                  apiPayload
                );
                if (saveBlacklistNodes.status === 'success' && saveBlacklistNodes.code === 200) {
                  console.log('saveBlacklistNodes', saveBlacklistNodes);
                  target.addClass('blacklisted');
                }
              }
            }
          },
          {
            id: 'remove_blck',
            content: 'Remove Blacklist',
            tooltipText: 'rm blacklist',

            onClickFunction: async function (event) {
              const target = event.target || event.cyTarget;

              if (target.hasClass('blacklisted')) {
                console.log('removeBlacklistNodes node data', event.target.data());

                const apiPayload = {
                  link_id: Number(modelId),
                  project_id: Number(experimentId),
                  black_nodes_data: {
                    [event.target.data().label]: [Number(event.target.data().id)]
                  }
                };
                const removeBlacklistNodes = await DeleteApiByUrl(
                  '/visual/neo4j/blacklist_nodes',
                  apiPayload
                );
                if (
                  removeBlacklistNodes.status === 'success' &&
                  removeBlacklistNodes.code === 200
                ) {
                  console.log('removeBlacklistNodes', removeBlacklistNodes);
                  target.removeClass('blacklisted');
                }
              }
            }
          }
        ]
      },
      {
        id: 'hide',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038-5.351 0-7.424-3.846-7.926-5a8.642 8.642 0 0 1 1.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 0 0 0 .633C2.073 12.383 4.367 19 12 19zm0-14c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657a.994.994 0 0 0 0-.633C21.927 11.617 19.633 5 12 5zm4.972 10.558-2.28-2.28c.19-.39.308-.819.308-1.278 0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0 1 12 7c5.351 0 7.424 3.846 7.926 5-.302.692-1.166 2.342-2.954 3.558z"></path></svg> Hide</div>',
        tooltipText: 'hide',
        selector: 'node, edge',
        onClickFunction: function (event) {
          const target = event.target || event.cyTarget;
          target.style('display', 'none');
        }
      },
      {
        id: 'MergeNode',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M208,116a28,28,0,0,0-27.71,24H152a44.2,44.2,0,0,1-35.2-17.6L87.28,83A28,28,0,1,0,76,83.71v88.58a28,28,0,1,0,8,0V92l26.4,35.2A52.26,52.26,0,0,0,152,148h28.29A28,28,0,1,0,208,116ZM60,56A20,20,0,1,1,80,76,20,20,0,0,1,60,56Zm40,144a20,20,0,1,1-20-20A20,20,0,0,1,100,200Zm108-36a20,20,0,1,1,20-20A20,20,0,0,1,208,164Z"></path></svg> Merge nodes</div>',
        tooltipText: 'Merge nodes',
        selector: 'node, edge',
        onClickFunction: async function (event) {
          const cyToState = event.cy;
          const ids = [];
          // console.log('targettarget', cyToState.$(':selected'));

          if (cyToState.$(':selected').length > 0) {
            cyToState.$(':selected').forEach((item) => {
              // console.log('mergeNodes', item.data());
              ids.push(Number(item.data().props.node_id));
            });
          }
          if (ids.length > 0) {
            const cred = {
              node_ids: ids
            };
            // http://127.0.0.1:8000/visual/neo4j/merge_nodes
            const mergeNodes = await PostApiByUrl('/visual/neo4j/merge_nodes', cred);
            // console.log('mergeNodes', mergeNodes);
            if (mergeNodes.status === 'success' && mergeNodes.code === 200) {
              setrecallModelNodes(new Date());
            }
          }
        }
      },
      {
        id: 'showAll',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M418.4 157.9c35.3-8.3 61.6-40 61.6-77.9c0-44.2-35.8-80-80-80c-43.4 0-78.7 34.5-80 77.5L136.2 151.1C121.7 136.8 101.9 128 80 128c-44.2 0-80 35.8-80 80s35.8 80 80 80c12.2 0 23.8-2.7 34.1-7.6L259.7 407.8c-2.4 7.6-3.7 15.8-3.7 24.2c0 44.2 35.8 80 80 80s80-35.8 80-80c0-27.7-14-52.1-35.4-66.4l37.8-207.7zM156.3 232.2c2.2-6.9 3.5-14.2 3.7-21.7l183.8-73.5c3.6 3.5 7.4 6.7 11.6 9.5L317.6 354.1c-5.5 1.3-10.8 3.1-15.8 5.5L156.3 232.2z"></path></svg> Show all nodes</div>',
        tooltipText: 'show all',
        selector: 'node, edge',
        coreAsWell: true,
        onClickFunction: function (event) {
          const cyToState = event.cy;
          cyToState.nodes().forEach((element) => {
            // console.log('element', element.data().id);
            cyToState.$('#' + element.data().id).style('display', 'element');
          });
        }
      },
      {
        id: 'expand',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9.04304 5.79297 2.83594 12.0001 9.04304 18.2072 10.4573 16.793 5.66436 12.0001 10.4573 7.20718 9.04304 5.79297ZM14.957 18.2073 21.1641 12.0002 14.957 5.79312 13.5427 7.20733 18.3356 12.0002 13.5427 16.7931 14.957 18.2073Z"></path></svg> Expand</div>',
        tooltipText: 'Expand',
        selector: 'node, edge',
        onClickFunction: async function (event) {
          const target = event.target || event.cyTarget;
          // console.log('targetNode', target);
          const cyToState = event.cy;
          const allEles = cyToState.elements();
          const highlightedNodes = target.closedNeighborhood();

          // Add the direct neighbors to the highlighted set
          let allSources = highlightedNodes;

          // Iterate over the direct neighbors and add their neighbors to the highlighted set
          highlightedNodes.forEach((node) => {
            const neighborsOfNeighbor = node.closedNeighborhood();
            allSources = allSources.add(neighborsOfNeighbor);
          });
          const others = allEles.not(allSources);
          cyToState.batch(() => {
            allEles.removeClass('faded highlighted hidden');

            allSources.addClass('highlighted');
            others.addClass('hidden');

            others.positions(getOrgPos);
          });

          const layout = allSources.layout({
            name: 'concentric',
            nodeSpacing: 15,
            edgeLengthVal: 45,
            animate: true,
            randomize: false,
            maxSimulationTime: 1500,
            fit: true
            // stop: () => {
            //   cyToState.animate(
            //     {
            //       fit: {
            //         eles: allSources,
            //         padding: 20
            //       }
            //     },
            //     {
            //       duration: 300
            //     }
            //   );
            // }
          });
          layout.promiseOn('layoutstop');
          layout.run();

          cyToState.batch(() => {
            others.removeClass('hidden').addClass('faded');
          });
        }
      },
      {
        id: 'collapse',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9 9H4v1h5V9z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M5 3l1-1h7l1 1v7l-1 1h-2v2l-1 1H3l-1-1V6l1-1h2V3zm1 2h4l1 1v4h2V3H6v2zm4 1H3v7h7V6z"></path></svg> Collapse</div>',
        tooltipText: 'collapse',
        selector: 'node, edge',
        coreAsWell: true,
        onClickFunction: async function (event) {
          const cyToState = event.cy;
          const allEles = cyToState.elements();
          // console.log('position', allEles);
          allEles.positions(getOrgPosCol);
          // console.log('position', 'abcd');
          allEles.removeClass('faded highlighted hidden');
          const layout = cyToState.layout({
            name: 'euler',
            springLength: (edge) => 150,
            mass: (node) => 30,
            randomize: true,
            fit: false,
            animate: false,
            stop: function () {
              cyToState.animate(
                {
                  pan: { x: 700, y: 300 },
                  zoom: 0.3
                },
                {
                  duration: 1800
                }
              );
            }
          });
          layout.run();
        }
      },
      {
        id: 'explode-node',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M229.5,113,166.07,90,143,26.5a16,16,0,0,0-30,0L90,89.93,26.5,113a16,16,0,0,0,0,30l63.43,23L113,229.5a16,16,0,0,0,30,0l23.07-63.44L229.5,143a16,16,0,0,0,0-30Zm-68.93,38a16,16,0,0,0-9.54,9.54L128,223.9l-23-63.33A16,16,0,0,0,95.43,151L32.1,128l63.33-23A16,16,0,0,0,105,95.43L128,32.1l23,63.33a16,16,0,0,0,9.54,9.54l63.33,23Z"></path></svg>Explode node</div>',
        tooltipText: 'explode node',
        selector: 'node',
        // coreAsWell: true,
        onClickFunction: async function (event) {
          const target = event.target || event.cyTarget;
          // console.log('targetXX', target.data().props.id);
          // if (nodeId.length > 0) {
          //   const explodeNodeCred = {
          //     action: 'explode',
          //     node_ids: target.data().props.id,
          //     limit: 10
          //   };
          //   // http://127.0.0.1:8000/visual/neo4j/merge_nodes
          //   const explodeNodes = await PostApiByUrl('/neo4j/explode_nodes', explodeNodeCred);
          //   console.log('explodeNodeCred', explodeNodeCred);
          //   if (explodeNodes.status === 'success' && explodeNodes.code === 200) {
          //     setRecallNode(new Date());
          //   }
          // }
        }
      },
      {
        id: 'explode-edge',
        content:
          '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M229.5,113,166.07,90,143,26.5a16,16,0,0,0-30,0L90,89.93,26.5,113a16,16,0,0,0,0,30l63.43,23L113,229.5a16,16,0,0,0,30,0l23.07-63.44L229.5,143a16,16,0,0,0,0-30Zm-68.93,38a16,16,0,0,0-9.54,9.54L128,223.9l-23-63.33A16,16,0,0,0,95.43,151L32.1,128l63.33-23A16,16,0,0,0,105,95.43L128,32.1l23,63.33a16,16,0,0,0,9.54,9.54l63.33,23Z"></path></svg>Explode edge</div>',
        tooltipText: 'explode edge',
        selector: 'edge',
        // coreAsWell: true,
        onClickFunction: function () {
          // console.log('explode edge');
          // setOpenDialog(true);
        }
      }
    ],
    // css classes that menu items will have
    // menuItemClasses: [
    //   // add class names to this list
    //   'rightClickMenuItem'
    // ],
    // css classes that context menu will have
    // contextMenuClasses: [
    //   // add class names to this list
    //   'rightClickMenuHolder'
    // ],
    // Indicates that the menu item has a submenu. If not provided default one will be used
    submenuIndicator: {
      src: subMenuSVG,
      width: 12,
      height: 12
    }
  });

  useEffect(() => {
    resetGraph();
  }, [cyToState, recallModelNodes, refreshKGComp]);

  // console.log('cytodata', cytoData);
  const [currentEdgesProperies, setCurrentEdgesProperties] = useState([]);
  const getEdgeProperties = (edgeSource, edgeTarget, cyToState) => {
    const allConnectedEdges = [];
    cyToState.edges().forEach((edge) => {
      console.log('edge.data()', edge.data());
      if (edge.data().source === edgeSource && edge.data().target === edgeTarget) {
        allConnectedEdges.push(edge.data().props);
      }
    });
    console.log('allConnectedEdges', allConnectedEdges);
    setCurrentEdgesProperties(allConnectedEdges);
  };

  useEffect(() => {
    console.log('currentEdgesProperies', currentEdgesProperies);
  }, [currentEdgesProperies]);

  const resetGraph = async () => {
    try {
      const authToken = userDataFromLocal();
      console.log('authToken', authToken);
      const params = `&user_id=${authToken.userID}`;

      setLoader((prevLoader) => ({ ...prevLoader, mainLoader: true }));
      const projectData = await GetApiByUrl(
        `visual/manage_projects?project_id=${experimentId}&user_id=${authToken.userID}`,
        authToken.token
      );
      if (projectData.status === 'success') {
        setCurrentprojectData(projectData.data);
      }

      const elementsData = await GetApiByUrl(
        'visual/neo4j/get_project_link_model?project_id=' +
          experimentId +
          '&link_id=' +
          modelId +
          params
      );
      if (
        elementsData.status === 'success' &&
        elementsData.code === 200 &&
        elementsData.data.length > 0
      ) {
        console.log('elementsData2', elementsData);
        const getMetadata = await GetApiByUrl(
          'visual/manage_metadata?link_id=' + modelId + '&project_id=' + experimentId
        );
        // console.log('getStylinggetStyling', getMetadata);
        if (getMetadata.status === 'success' && getMetadata.code === 200) {
          setCurrentmetaData(getMetadata.data);
          // console.log('getMetadata1', getMetadata.data.metadata);
          if (getMetadata.data.metadata && getMetadata.data.metadata.length > 0) {
            // console.log('getMetadata2', getMetadata.data.metadata[0]);
            if (
              getMetadata.data.metadata[0].others &&
              getMetadata.data.metadata[0].nodes_metadata
            ) {
              const {
                others: { savedDatasetId, stylying },
                meta_id,
                nodes_metadata
              } = getMetadata.data.metadata[0];

              setCurrentMetaDataId(meta_id);
              console.log('checkthismetadatastylying', stylying);
              elementsData.data.forEach((element, elmIndex) => {
                console.log('elmIndex', element);
                nodes_metadata.forEach((metadata, metaIndex) => {
                  if (metadata.labelType) {
                    if (element.node1.label === metadata.label) {
                      console.log('elmIndexelmIndex', element.node1.properties, metadata.labelType);
                      console.log('elmIndexelmIndex', element.node2.properties, metadata.labelType);
                      if (
                        element.node1.properties[metadata.labelType] &&
                        element.node1.properties[metadata.labelType] !== ''
                      ) {
                        console.log(
                          'metadata.labelType',
                          element.node1.properties[metadata.labelType]
                        );
                        element.node1.labelType = element.node1.properties[metadata.labelType];
                      }
                    }
                    if (element.node2.label === metadata.label) {
                      if (
                        element.node2.properties[metadata.labelType] &&
                        element.node2.properties[metadata.labelType] !== ''
                      ) {
                        // console.log(
                        //   'metadata.labelType',
                        //   element.node2.properties[metadata.labelType]
                        // );
                        element.node2.labelType = element.node2.properties[metadata.labelType];
                      }
                    }
                  }
                });
              });
              const graphDataArray = createNodesAndEdges(elementsData);
              //   console.log('graphDataArray123', graphDataArray);
              setCytoData(graphDataArray);
              setStyling([...graphStyling, ...stylying, ...commonStyle]);

              if (cyToState) {
                const layout = cyToState.layout({
                  name: layOutName,
                  springLength: (edge) => 150,
                  mass: (node) => 30,
                  randomize: true,
                  fit: false,
                  animate: false,
                  stop: function () {
                    if (tabValue !== 3) {
                      cyToState.animate(
                        {
                          pan: { x: 700, y: 300 },
                          zoom: 0.3
                        },
                        {
                          duration: 2000
                        }
                      );
                    }
                  }
                });

                // console.log('metadata.labelType', cyToState.center());
                // const layout = cyToState.layout({
                //   name: 'cose',
                //   animate: 'end',
                //   fit: false,
                //   nodeOverlap: 100,
                //   stop: function () {
                //     cyToState.animate(
                //       {
                //         pan: { x: -1000, y: -510 },
                //         zoom: 0.7
                //       },
                //       {
                //         duration: 3000
                //       }
                //     );
                //   }
                // });
                layout.run();
              }
            }
          }
        }
        if (cyToState !== null) {
          // console.log('iscyToState', cyToState);
          const allNodesInGraph = [];
          cyToState.nodes().forEach((node) => allNodesInGraph.push(node.data()));
          setAllNodeForInfo(allNodesInGraph);
          // cyToState.fit();
          // const layout = cyToState.layout({
          //   name: 'cola',
          //   nodeSpacing: 15,
          //   edgeLengthVal: 45,
          //   animate: true,
          //   randomize: false,
          //   maxSimulationTime: 1500
          // });
          // layout.run();
          setLoader((prevLoader) => ({ ...prevLoader, mainLoader: false }));
          const instance = cyToState.contextMenus(graphRightClick);

          cyToState.on('tap', 'node', (evt) => {
            panelLeftSideBar('hideBehind');
            // setIsExpanded('panel2');
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'expanded',
                value: 'panel2'
              }
            });
            const selectedNodes = cyToState.$(':selected');
            if (selectedNodes.length >= 0) {
              const rightClickMenuItems = graphRightClick;
              const conditionalItem1 = {
                id: 'central-node',
                content:
                  '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m5.005 15.995 4-4-4-4v3h-3v2h3zm14-5v-3l-4 4 4 4v-3h3v-2h-2.072zm-8 7h2v3h-2zm0-5h2v3h-2zm0-5h2v3h-2zm0-5h2v3h-2z"></path></svg>Center Node</div>',
                tooltipText: 'Center Node',
                selector: 'node', // Adjust the selector as needed
                coreAsWell: true,
                onClickFunction: function (event) {
                  const centerNodeId = event.target;
                  // Handle the click event for the conditional menu item
                  const nodePosition = centerNodeId.position();
                  const viewPortCenter = {
                    x: cyToState.width(),
                    y: cyToState.height()
                  };
                  const panX = viewPortCenter.x / 2 - nodePosition.x;
                  const panY = viewPortCenter.y / 2 - nodePosition.y;
                  cyToState.zoom({
                    level: 1,
                    position: centerNodeId.position()
                  });
                  cyToState.animate({
                    pan: { x: panX, y: panY },
                    duration: 600 // Adjust the duration as needed
                  });
                }
              };
              const conditionalItem2 = {
                id: 'number-of-hops',
                content:
                  '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M872 394c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H400V152c0-4.4-3.6-8-8-8h-64c-4.4 0-8 3.6-8 8v166H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v236H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h168v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h228v166c0 4.4 3.6 8 8 8h64c4.4 0 8-3.6 8-8V706h164c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8H708V394h164zM628 630H400V394h228v236z"></path></svg>Number of HOPs</div>',
                tooltipText: 'No. of HOPs',
                selector: 'node', // Adjust the selector as needed
                coreAsWell: true,
                onClickFunction: function () {
                  // Handle the click event for the conditional menu item
                  // console.log('No. of HOPs');
                }
              };
              const updatedMenuItems = [
                ...rightClickMenuItems.menuItems,
                conditionalItem1,
                conditionalItem2
              ];
              setGraphRightClick({
                ...rightClickMenuItems,
                menuItems: updatedMenuItems
              });
            }
            if (selectedNodes.length === 1) {
              let rightClickMenuItems = graphRightClick;
              const conditionalItem = {
                id: 'shortest-path',
                content:
                  '<div class="menuItemHolder"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M21 11l-6-6v5H8c-2.76 0-5 2.24-5 5v4h2v-4c0-1.65 1.35-3 3-3h7v5l6-6z"></path></svg>Shortest Path</div>',
                tooltipText: 'Shortest Path',
                selector: 'node', // Adjust the selector as needed
                coreAsWell: true,
                onClickFunction: function () {
                  // Handle the click event for the conditional menu item
                  // console.log('Shortest distance');
                }
              };
              rightClickMenuItems = {
                ...rightClickMenuItems,
                menuItems: [...rightClickMenuItems.menuItems, conditionalItem]
              };
              setGraphRightClick(rightClickMenuItems);
              // console.log('Two nodes shortestpath', rightClickMenuItems);
            }
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'slectedOption',
                value: 'node'
              }
            });
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'currentNodeid',
                value: evt.target.data().id
              }
            });
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'currentNodeInfo',
                value: evt.target.data()
              }
            });
            // dispatch({
            //   type: 'STORE_ALL_SELECTED_DATA',
            //   payload: {
            //     key: 'expanded',
            //     value: 'panel2'
            //   }
            // });
          });

          const selectedEdges = [];

          cyToState.nodes().on('mouseover', function (e) {
            // const allSources = e.target.closedNeighborhood();
            // const allEles = cyToState.elements();
            // const others = allEles.not(allSources);
            // cyToState.batch(() => {
            //   allEles.removeClass('activeNode');
            //   allSources.addClass('activeNode');
            // });

            const _outgoers = e.target.outgoers();
            const _incomers = e.target.incomers();
            // console.log(
            //   'isMouseOver',
            //   _outgoers.map((item) => item.data())
            // );

            const allEles = cyToState.elements();

            cyToState.batch(() => {
              allEles.removeClass('c_Target c_Source activeNode');

              e.target.addClass('activeNode');

              // _outgoers.removeClass('c_Source');
              _outgoers.addClass('c_Target');

              // _incomers.removeClass('c_Target');
              _incomers.addClass('c_Source');
            });

            // e.cyToState.container().style.cursor = 'pointer';

            // const j = cyToState.$('#' + e.target.data().id);
            // console.log('isMouseOver', j.connectedEdges().length);
            // cyToState.$('#' + e.target).addClass('activeNode');
            // j.connectedEdges().forEach((item, index) => {
            //   selectedEdges.push(item);
            //   console.log('isMouseOver2', item);
            //   item.addClass('activeNode');
            //   // cyToState.style().selector(item).style('color', 'magenta').update(); // indicate the end of your new stylesheet so that it can be updated on elements
            //   const targetId = '#' + item.data().target;
            //   if (cyToState.$(targetId).data().blacklisted_flag === 'Yes') {
            //     item.addClass('activeNodeRed');
            //   }
            // });
            // settargetnode(e.target.data());
          });

          cyToState.nodes().on('mouseout', function (e) {
            // console.log('classes removed');
            cyToState.elements().removeClass('activeNode c_Source c_Target');
            // cyToState.elements().removeClass('activeNodeRed');
          });

          cyToState.edges().on('mouseover', function (e) {
            const allEles = cyToState.elements();

            cyToState.batch(() => {
              allEles.removeClass('c_Target c_Source activeNode');
              e.target.addClass('activeNode');
            });
          });

          cyToState.edges().on('mouseout', function (e) {
            // console.log('classes removed');
            cyToState.elements().removeClass('activeNode c_Source c_Target');
            //  cyToState.elements().removeClass('activeNodeRed');
          });

          cyToState.on('boxselect', (evt) => {
            // console.log('getSelected', evt.target);
          });

          cyToState.on('tap', 'edge', (evt) => {
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'slectedOption',
                value: 'edge'
              }
            });
            openEdgePropertyModel(true);
            console.log('evt.target.data()', evt.target.data());
            const edgeSource = evt.target.data().source;
            const edgeTarget = evt.target.data().target;
            getEdgeProperties(edgeSource, edgeTarget, cyToState);
            // panelLeftSideBar('hideBehind');
            // setIsExpanded('panel3');
            // dispatch({
            //   type: 'STORE_ALL_SELECTED_DATA',
            //   payload: {
            //     key: 'expanded',
            //     value: 'panel3'
            //   }
            // });
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'currentEdgeId',
                value: evt.target.data().id
              }
            });
            dispatch({
              type: 'STORE_ALL_SELECTED_DATA',
              payload: {
                key: 'currentEdgeData',
                value: evt.target.data()
              }
            });
            // console.log(evt.target.data(), 'myPleasure');
          });

          cyToState.on('tap', (evt) => {
            // evt.target to target clicked elm
            if (evt.target === cyToState) {
              // ** Gautam - This option may restored ** //
              // setStyling([...graphStyling, ...commonStyle]);
              // cyToState.elements().style({
              //   opacity: 1,
              //   events: 'yes',
              //   'border-width': 4,
              //   'border-color': 'rgb(106, 198, 255)',
              //   'line-color': 'rgb(253, 204, 89)',
              //   'target-arrow-color': 'rgb(253, 160, 60)'
              // });

              // cyToState.fit();

              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: {
                  key: 'slectedOption',
                  value: ''
                }
              });

              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: {
                  key: 'currentNodeid',
                  value: ''
                }
              });

              dispatch({
                type: 'STORE_ALL_SELECTED_DATA',
                payload: {
                  key: 'currentEdgeData',
                  value: {}
                }
              });
            }
          });
          setLoader((prevLoader) => ({ ...prevLoader, mainLoader: false }));
          const allNodesLabels = [];
          for (let i = 0; i < cyToState.nodes().length; i++) {
            // console.log('cyToState.nodes()', cyToState.nodes()[i]);
            const isExits = allNodesLabels.filter(
              (item) => item.label === cyToState.nodes()[i].data().label
            );
            if (isExits.length === 0) {
              console.log('LabelToDisplay');
              allNodesLabels.push({
                ...cyToState.nodes()[i].data(),
                ...{
                  'background-color': cyToState.nodes()[i].style()['background-color'],
                  'background-image': cyToState.nodes()[i].style()['background-image']
                }
              });
            }
          }
          if (cyToState.nodes().length > 0) {
            const getBlacklistNodes = await GetApiByUrl(
              'visual/neo4j/blacklist_nodes?link_id=' + modelId + '&project_id=' + experimentId
            );
            if (getBlacklistNodes.status === 'success' && getBlacklistNodes.code === 200) {
              const result = getBlacklistNodes.data.find(
                (item) =>
                  Number(item.link_id) === Number(modelId) &&
                  Number(item.project_id) === Number(experimentId)
              );

              if (result) {
                const { black_nodes_data } = result;

                const blacklistedNodeIds = Object.values(black_nodes_data).flat().map(Number);

                const blacklistedNodes = cyToState
                  .nodes()
                  .filter((item) => blacklistedNodeIds.includes(Number(item.data().id)));

                console.log(
                  'getBlacklistNodes cyto filtered',
                  blacklistedNodes.nodes().map((item) => item.data())
                );

                blacklistedNodes.addClass('blacklisted');
              } else {
                console.log('No blacklist data found for the given modelId and experimentId.');
              }
            }
          }

          if (allNodesLabels?.length > 0) {
            const _newData = {};
            allNodesLabels.forEach((node) => {
              _newData[node.label] = {
                'background-color': '',
                label: 'data(label)',
                height: '',
                width: '',
                padding: '',
                'background-fit': '',
                'background-opacity': '',
                'border-width': '',
                'border-style': '',
                'border-color': '',
                'border-opacity': '',
                shape: '',
                'background-repeat': '',
                'background-image': '',
                'background-image-opacity': '',
                'font-size': '',
                // 'text-margin-y': '-7px',
                'text-valign': '',
                'text-halign': '',
                color: '',
                'text-opacity': ''
              };
            });
            console.log(_newData, '_newData');
            dispatch({
              type: 'STYLING_DATA_UPDATE',
              payload: {
                key: 'vizNodeStyles',
                value: _newData
              }
            });
          }

          setLabelToDisplay(allNodesLabels);

          const allEdgesLabels = [];
          for (let i = 0; i < cyToState.edges().length; i++) {
            const isExits = allEdgesLabels.filter(
              (item) => item.label === cyToState.edges()[i].data().label
            );
            if (isExits.length === 0) {
              allEdgesLabels.push({
                ...cyToState.edges()[i].data(),
                ...{ 'line-color': cyToState.edges()[i].style()['line-color'] }
              });
            }
          }

          if (allEdgesLabels?.length > 0) {
            const _newData = {};
            allEdgesLabels.forEach((node) => {
              _newData[node.label] = {
                'line-color': '#f9ab62',
                label: 'data(label)',
                width: '2',
                padding: '0',
                'curve-style': 'unbundled-bezier',
                'line-style': 'solid',
                'target-arrow-shape': 'triangle',
                'target-arrow-color': '#c4c4c4',
                'arrow-scale': 1,
                'line-opacity': 1,
                'font-size': '9',
                // 'text-margin-y': '-7px',
                'text-valign': 'center',
                'text-halign': 'center',
                color: '#000',
                'text-opacity': 1
              };
            });
            console.log(_newData, '_newData');
            dispatch({
              type: 'STYLING_DATA_UPDATE',
              payload: {
                key: 'vizEdgeStyles',
                value: _newData
              }
            });
          }
          setLabelEdgeToDisplay(allEdgesLabels);
        }
        setLoader((prevLoader) => ({ ...prevLoader, switchModels: false }));
      } else if (
        // elementsData.status === 'success' &&
        elementsData.code === 403
      ) {
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: elementsData.message }
          }
        });
      } else {
        // console.log('metadata api');
        setLoader((prevLoader) => ({ ...prevLoader, mainLoader: false }));
        dispatch({
          type: 'STORE_ALL_SELECTED_DATA',
          payload: {
            key: 'apiErrorDialogValue',
            value: { dialog: true, message: 'There was an error while processing your request.' }
          }
        });
      }
    } catch (error) {
      console.log('reset graph error', error);
    } finally {
      setLoader((prevLoader) => ({ ...prevLoader, mainLoader: false, switchModels: false }));
    }
  };

  const [openRuleModel, setOpenRuleModel] = useState(false);
  const [patchToggleSaveModal, setPatchToggleSaveModal] = useState(false);
  const [dynamicGlobalRuleEngineKey, setDynamicGlobalRuleEngineKey] = useState(
    'dynamicGlobalRuleEngineKey'
  );

  // console.log('satyam', selectedGraphTabValue);
  useEffect(() => {
    if (cytoData.length > 1) {
      const updatedCytoDataAllGraph = [{ label: selectedGraphTabValue, graphData: [...cytoData] }];
      setDisplayCytoData(cytoData);

      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'cytoDataAllGraph',
          value: [...updatedCytoDataAllGraph]
        }
      });

      console.log('filtredIds updatedCytoDataAllGraph', updatedCytoDataAllGraph);
      console.log('filtredIds cytodata', cytoData, displayCytoData);
    }
  }, [cytoData]);

  useEffect(() => {
    const matchedObject = cytoDataAllGraph.find(
      (item) => item.label.toLowerCase() === selectedGraphTabValue.toLowerCase()
    );

    if (matchedObject) {
      // console.log('satyam match:', matchedObject);
      const graphDataForSelectedLabel = matchedObject.graphData;
      setDisplayCytoData(graphDataForSelectedLabel);
      // console.log(graphDataForSelectedLabel);
    } else {
      setDisplayCytoData([]);

      const newData = [...cytoDataAllGraph];
      newData.push({ label: selectedGraphTabValue, graphData: [] });
      dispatch({
        type: 'STORE_ALL_SELECTED_DATA',
        payload: {
          key: 'cytoDataAllGraph',
          value: [...newData]
        }
      });
      // console.log('satyam: notMatch', cytoDataAllGraph);
      // console.log('satyam: notMatchNew', newData);
    }

    setTimeout(() => {
      if (cyToState) {
        const layout = cyToState.layout({
          name: 'euler',
          springLength: (edge) => 150,
          mass: (node) => 30,
          randomize: true,
          fit: false,
          animate: false,
          stop: function () {
            cyToState.animate(
              {
                pan: { x: 700, y: 300 },
                zoom: 0.3
              },
              {
                duration: 1800
              }
            );
          }
        });
        layout.run();
      }
    }, 1000);

    // dispatch({
    //   type: 'STORE_ALL_SELECTED_DATA',
    //   payload: {
    //     key: 'cytoInstance',
    //     value: Cytoscape(cytoscapeComponentRef)
    //   }
    // });
  }, [selectedGraphTabValue, kgUpdate]);

  // console.log('cytodata', cytoData);

  const panelLeftSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBar',
        value: newValue
      }
    });
  };

  const panelRightSideBar = (newValue) => {
    dispatch({
      type: 'STORE_ALL_SELECTED_DATA',
      payload: {
        key: 'hideSideBarRight',
        value: newValue
      }
    });
  };

  const [globalSavedRulesNameList, setGlobalSavedRulesNameList] = useState([]);
  console.log(globalSavedRulesNameList, 'globalSavedRulesNameList');
  const [mutateSavedRuleList, setMutateSavedRuleList] = useState(new Date());
  const [edgeSavedRulesNameList, setEdgeSavedRulesNameList] = useState([]);
  const [nodeSavedRulesNameList, setNodeSavedRulesNameList] = useState([]);
  const [toggleLegendHolder, setToggleLegendHolder] = useState(true);
  const [globalSavedRuleValue, setGlobalSavedRuleValue] = useState([]);
  const [allContentLoaded, setAllContentLoaded] = useState(false);
  const [rightPanelConfirm, setRightPanelConfirm] = useState(false);
  let interval;

  const handleExitTimeline = () => {
    setTabValue(0);
    setToggleLegendHolder(true);
  };

  useEffect(() => {
    setSelectedGlobalRules([]);
  }, [mutateSavedRuleList]);

  useEffect(() => {
    (async () => {
      const authToken = userDataFromLocal();
      console.log('authToken', authToken);
      const params = `project_id=${experimentId}&user_id=${authToken.userID}`;
      const apiResponse = await GetApiByUrl(`visual/manage_rule_engine?${params}`);

      if (apiResponse.status === 'success' && apiResponse.code === 200) {
        console.log('rule saved Response api', apiResponse.data);

        const _globalRules = [];
        const _edgeRules = [];
        const _nodeRules = [];

        apiResponse.data.map((item) => {
          const { rule_name, rule_id, project_id_id } = item;
          if (Number(experimentId) === Number(project_id_id)) {
            if (item.isGlobal) {
              _globalRules.push({ label: rule_name, id: rule_id });
            } else if (!item.isGlobal && item.rule_type === 'node_rule') {
              _nodeRules.push({ label: rule_name, id: rule_id });
            } else if (!item.isGlobal && item.rule_type === 'edge_rule') {
              _edgeRules.push({ label: rule_name, id: rule_id });
            }
          }
        });
        // console.log('rule saved Response custom', options);
        setGlobalSavedRulesNameList(_globalRules);
        setEdgeSavedRulesNameList(_edgeRules);
        setNodeSavedRulesNameList(_nodeRules);
      } else {
        console.log('rule saved Response Failed', apiResponse);
        setGlobalSavedRulesNameList([]);
        setEdgeSavedRulesNameList([]);
        setNodeSavedRulesNameList([]);
      }
    })();
  }, [mutateSavedRuleList]);

  const handleGlobalSavedRuleSelection = (_, value) => {
    setSelectedGlobalRules(value);
    // if (value.label) {
    //   setGlobalSavedRuleValue(value.id);
    //   // console.log('setGlobalSavedRuleValue', value);
    // } else {
    //   setGlobalSavedRuleValue('');
    //   // console.log('satyam: error');
    // }
  };

  const filterNodesWithRule = () => {
    if (selectedGlobalRules.length > 0) {
      setApiLoader(true);
      // console.log('loadingapi', apiLoader);
      (async () => {
        const globalSavedRuleValue = selectedGlobalRules[0].id;
        const getApiData = await GetApiByUrl('visual/apply_rule?rule_id=' + globalSavedRuleValue);

        if (getApiData.status === 'success' && getApiData.code === 200) {
          console.log('getApiData', getApiData.data);

          if (selectedGlobalRules[0].label.startsWith('edge:')) {
            const uniqueNodeIds = Array.from(
              new Set(
                getApiData.data.map(({ relationship, node1, node2 }) => ({
                  source_id: String(node1.properties.node_id),
                  target_id: String(node2.properties.node_id),
                  name: relationship.name
                }))
              )
            );
            // console.log(uniqueNodeIds, 'uniqueNodeIds');
            const _filteredEdges = cyToState
              .edges()
              .filter((item) =>
                uniqueNodeIds.some(
                  (edge) =>
                    edge.source_id === item.data().source &&
                    edge.target_id === item.data().target &&
                    edge.name === item.data().label
                )
              );

            console.log(_filteredEdges, '_filteredEdges');
            if (_filteredEdges.length > 0) {
              cyToState.elements().addClass('faded');
              _filteredEdges.addClass('selected').removeClass('faded');
            } else {
              setApiLoader(false);
              enqueueSnackbar('No data found for this rule', {
                variant: 'warning',
                autoHideDuration: 5000
              });
            }
            // console.log(
            //   _filteredEdges.map((item) => item.data()),
            //   'uniqueNodeIds _filteredEdges'
            // );
          } else {
            const filtredIds = [];
            getApiData.data.forEach((item) => filtredIds.push(String(item.properties.node_id)));

            const _styleFilterIds = cyToState
              .nodes()
              .filter((elm) => [...new Set(filtredIds)].includes(String(elm.data().props.node_id)));

            if (filtredIds.length > 0 && _styleFilterIds.length > 0) {
              cyToState.elements().forEach((element) => {
                // console.log('element.isNode()2', element.data().props.node_id);
                // console.log('element.isNode()2', element.data('props').node_id);
                element.addClass('faded');
                if ([...new Set(filtredIds)].includes(String(element.data().props.node_id))) {
                  element.addClass('selected').removeClass('faded');
                } else if (
                  element.group === 'edges' &&
                  [...new Set(filtredIds)].includes(String(element.data().props.node_id))
                ) {
                  element.addClass('selected').removeClass('faded');
                }
                console.log('element.isNode()2', element);
              });

              setApiLoader(false);
              enqueueSnackbar('Rule Applied Successfully', {
                variant: getApiData.status,
                autoHideDuration: 5000
              });
            } else {
              setApiLoader(false);
              enqueueSnackbar('No data found for this rule', {
                variant: 'warning',
                autoHideDuration: 5000
              });
            }
          }
        } else {
          setApiLoader(false);
          enqueueSnackbar('Problem in applying rules.', {
            variant: 'error',
            autoHideDuration: 5000
          });
        }
      })();
    } else {
      enqueueSnackbar('Please select rule(s).', {
        variant: 'warning',
        autoHideDuration: 3000
      });
    }

    // cyToState.remove(cyToState.collection());
    // setCytoData(filtredElements);
    // cy.add(filtredElements);
    // const layout = cyToState.layout({
    //   name: 'euler',
    //   springLength: (edge) => 150,
    //   mass: (node) => 30,
    //   randomize: true,
    //   fit: false,
    //   animate: false,
    //   stop: function () {
    //     cyToState.animate(
    //       {
    //         pan: { x: 700, y: 300 },
    //         zoom: 0.3
    //       },
    //       {
    //         duration: 1800
    //       }
    //     );
    //   }
    // });
    // layout.run();
    // console.log('filtredIds', filtredElements);
  };

  const handleDeleteRules = () => {
    const authToken = userDataFromLocal();
    const ruleCredential = {
      rule_ids: selectedGlobalRules.map((item) => item.id),
      creator_user_id: authToken.userID
    };

    (async () => {
      if (selectedGlobalRules.length > 0) {
        const deleteRules = await DeleteApiByUrl('/visual/manage_rule_engine', ruleCredential);

        if (deleteRules?.status === 'success' && deleteRules?.code === 200) {
          setSelectedGlobalRules([]);

          setGlobalSavedRulesNameList((prevRules) =>
            prevRules.filter((rule) => !ruleCredential.rule_ids.includes(rule.id))
          );

          enqueueSnackbar(deleteRules.message, {
            variant: deleteRules.status,
            autoHideDuration: 3000
          });
        } else {
          enqueueSnackbar('Problem in rule deletion', {
            variant: 'error',
            autoHideDuration: 3000
          });
        }
      } else {
        enqueueSnackbar('Please select rule(s).', {
          variant: 'warning',
          autoHideDuration: 3000
        });
      }
    })();
  };

  useEffect(() => {
    if (allContentLoaded) {
      setTimeout(() => {
        handleLegendHolderToggle();
      }, 4000);
    }
  }, [allContentLoaded]);

  const handleLegendHolderToggle = () => {
    const _legendHolder = document.getElementById('legendHolder');
    const _legendContainer = document.getElementById('legendContainer');
    if (_legendContainer && _legendHolder) {
      if (toggleLegendHolder) {
        _legendContainer.style.transform = `translateY(${_legendHolder.offsetHeight - 2}px)`;
        setToggleLegendHolder(false);
      } else {
        _legendContainer.style.transform = 'translateY(0px)';
        setToggleLegendHolder(true);
      }
    }
  };

  const [openMediaDialog, setOpenMediaDialog] = useState(false);
  const [mediaDialogContent, setMediaDialogContent] = useState('');

  // Regex to identify URLs
  const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/i;

  const isImage = (url) => /\.(jpeg|jpg|gif|png|bmp|webp)$/i.test(url);
  const isVideo = (url) => /\.(mp4|webm|ogg)$/i.test(url);
  const isAudio = (url) => /\.(mp3|wav|ogg)$/i.test(url);

  const getVideoType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
      case 'mp4':
        return 'mp4';
      case 'webm':
        return 'webm';
      case 'ogg':
        return 'ogg';
      default:
        return ''; // Handle unknown video formats
    }
  };

  const getAudioType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    switch (extension) {
      case 'mp3':
        return 'mpeg';
      case 'wav':
        return 'wav';
      case 'ogg':
        return 'ogg';
      default:
        return ''; // Handle unknown audio formats
    }
  };

  // Function to handle dialog open with specific content
  const handleOpenMediaDialog = (content) => {
    setMediaDialogContent(content);
    setOpenMediaDialog(true);
  };

  // Function to handle dialog close
  const handleCloseMediaDialog = () => {
    setOpenMediaDialog(false);
    setMediaDialogContent('');
  };

  return (
    <Page title="Knowledge Graph" ref={elementReference} className="pageNodeStyle">
      <ValidationMsgOnly
        openValidation={openValidation}
        validationMsg={validationMsg}
        handleClose={handleClose}
      />
      <ApiErrorDialog setRefreshKGComp={setRefreshKGComp} />
      <LoaderOpaque setAllContentLoaded={setAllContentLoaded} />
      {loader.switchModels && <LoaderBlur bgProp="white" />}
      {apiLoader && <LoaderBlur customMsg="Rules are being applied" />}
      <Container
        maxWidth="xl"
        className="noPadding"
        // onMouseMove={(e) => showHideNode(e)}
      >
        {tabValue === 0 && (
          <Box id="settingPanel">
            <Box id="settingView" onClick={() => panelRightSideBar(true)}>
              <Box className="rotateCogs">
                <FaCog />
              </Box>
            </Box>

            <Dialog open={isEdgePropertyModel} onClose={(e) => openEdgePropertyModel(null)}>
              {/* // onClose={(e) => handleCloseVisualization()} */}
              <DialogTitle>Edge Property</DialogTitle>
              <DialogContent>
                {currentEdgesProperies.length > 0 ? (
                  <Box>
                    <TableContainer component={Paper} className="columnMappingContainer">
                      <Table aria-label="simple table" className="columnMappingTable">
                        <TableHead>
                          <TableRow className="columnMappingRowTh">
                            {Object.keys(currentEdgesProperies[0]).map((key) => (
                              <TableCell className="tableCells" width={120}>
                                {key}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentEdgesProperies.map((property, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0
                                }
                              }}
                              className="columnMappingRowTd"
                            >
                              {Object.values(property).map((key) => (
                                <TableCell
                                  className="tableCells"
                                  width="90px"
                                  component="td"
                                  scope="row"
                                >
                                  {key}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  'No properties found'
                )}
              </DialogContent>
            </Dialog>
            <Dialog open={rightPanelConfirm} onClose={() => setRightPanelConfirm(false)}>
              <DialogTitle sx={{ textAlign: 'center' }}>Confirmation Message</DialogTitle>
              <DialogContent sx={{ padding: '20px 10px', width: '400px' }}>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Please confirm if you wish to proceed. Once you close this panel, the styling will
                  revert to default when you reopen it.
                  {/* <strong>&nbsp;irreversible</strong>. */}
                </Typography>
                {/* <Typography sx={{ mt: 2 }}>
                  Please check that all the column data types are correct or valid.
                </Typography> */}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setRightPanelConfirm(false)}>Back to edit</Button>
                <Button
                  onClick={() => {
                    panelRightSideBar(false);
                    setRightPanelConfirm(false);
                  }}
                >
                  Yes, I am sure
                </Button>
              </DialogActions>
            </Dialog>

            <SwipeableDrawer
              anchor="right"
              variant="persistent"
              classes={{ paper: 'SwipeableDrawer' }}
              open={hideSideBarRight}
              onClose={(e) => panelRightSideBar(false)}
              onOpen={(e) => panelRightSideBar(true)}
            >
              {hideSideBarRight && (
                <Box className="drawerWrap demoscrollbar">
                  <Box
                    id="settingView"
                    className="close"
                    // onClick={(e) => setRightPanelConfirm(true)}
                    onClick={(e) => panelRightSideBar(false)}
                  >
                    <RenderIcons icon={getIcon(CloseIcon, null, 18, 18)} />
                  </Box>

                  <CanvasDisplayData
                    cyToState={cyToState}
                    expandedPanel={expandedPanel}
                    handleExpanedPanel={handleExpanedPanel}
                    CurrentLayoutName={layOutName}
                  />

                  <NodeStyleSetting
                    currentMetaDataId={currentMetaDataId}
                    nodeSavedRulesNameList={nodeSavedRulesNameList}
                    setNodeSavedRulesNameList={setNodeSavedRulesNameList}
                    setMutateSavedRuleList={setMutateSavedRuleList}
                    mutateSavedRuleList={mutateSavedRuleList}
                    cyToState={cyToState}
                    labelToDisplay={labelToDisplay}
                    setLabelToDisplay={setLabelToDisplay}
                    expandedPanel={expandedPanel}
                    handleExpanedPanel={handleExpanedPanel}
                  />

                  <EdgeStyleSetting
                    currentMetaDataId={currentMetaDataId}
                    edgeSavedRulesNameList={edgeSavedRulesNameList}
                    setEdgeSavedRulesNameList={setEdgeSavedRulesNameList}
                    setMutateSavedRuleList={setMutateSavedRuleList}
                    mutateSavedRuleList={mutateSavedRuleList}
                    cyToState={cyToState}
                    labelEdgeToDisplay={labelEdgeToDisplay}
                    setLabelEdgeToDisplay={setLabelEdgeToDisplay}
                    expandedPanel={expandedPanel}
                    handleExpanedPanel={handleExpanedPanel}
                    graphStyling={graphStyling}
                  />
                  {/* satyam rule engine modal */}
                  <Modal
                    open={openRuleModel || patchToggleSaveModal}
                    onClose={() => {
                      setOpenRuleModel(false);
                      setPatchToggleSaveModal(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    keepMounted
                  >
                    <Box
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '95%',
                        background: 'white',
                        border: '1px solid #ddd',
                        boxShadow: 24,
                        padding: '1rem',
                        maxHeight: '80vh',
                        borderRadius: 8,
                        overflow: 'scroll'
                      }}
                    >
                      <RuleEngineQuery
                        key={dynamicGlobalRuleEngineKey}
                        setDynamicGlobalRuleEngineKey={setDynamicGlobalRuleEngineKey}
                        setOpenRuleModel={setOpenRuleModel}
                        setPatchToggleSaveModal={setPatchToggleSaveModal}
                        savedRulesNameList={globalSavedRulesNameList}
                        setSavedRulesNameList={setGlobalSavedRulesNameList}
                        patchToggleSaveModal={patchToggleSaveModal}
                        setMutateSavedRuleList={setMutateSavedRuleList}
                        selectedRules={selectedGlobalRules}
                        ruleType="global_rule"
                      />
                    </Box>
                  </Modal>
                  {/* satyam rule engine modal end */}

                  <Box sx={{ pb: 0 }} className="nodeDesign">
                    <Accordion
                      className="accordT"
                      expanded={expandedPanel === 'panel5'}
                      onChange={handleExpanedPanel('panel5')}
                    >
                      <AccordionSummary
                        classes={{
                          root: 'accordTrigger',
                          content: 'accordTgrtxt'
                        }}
                        expandIcon={<ExpandMore />}
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                      >
                        <Typography variant="h5" className="nodeAccordHead">
                          Filter Graph
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: 1
                          }}
                          className="ruleEngine"
                        >
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            size={'small'}
                            onChange={handleGlobalSavedRuleSelection}
                            value={selectedGlobalRules}
                            sx={{ width: '100%', display: 'inline-block' }}
                            options={globalSavedRulesNameList.map((option) => option)}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  size="small"
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />

                                {option.label}
                              </li>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder={'Select Rule'}
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true
                                }}
                              />
                            )}
                          />
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%'
                            }}
                          >
                            <ButtonGroup
                              variant="contained"
                              aria-label="outlined button group"
                              sx={{ width: '100%' }}
                            >
                              <Button
                                disabled={selectedGlobalRules.length > 1}
                                variant="contained"
                                sx={groupBtnStyle}
                                style={groupBtnStyleBdr}
                                onClick={() => {
                                  if (
                                    userDataFromLocal().modules.some(
                                      (module) => module.module_name === 'Rules'
                                    )
                                  ) {
                                    if (
                                      userDataFromLocal().modules.find(
                                        (module) => module.module_name === 'Rules'
                                      ).permissions.view
                                    ) {
                                      filterNodesWithRule(cyToState);
                                    } else {
                                      setValidationMsg(
                                        'User does not have permission to view apply.'
                                      );
                                      setOpenValidation(true);
                                    }
                                  }
                                }}
                              >
                                Apply
                              </Button>
                              <Button
                                variant="contained"
                                sx={groupBtnStyle}
                                style={groupBtnStyleBdr}
                                onClick={() => {
                                  if (
                                    userDataFromLocal().modules.some(
                                      (module) => module.module_name === 'Rules'
                                    )
                                  ) {
                                    if (
                                      userDataFromLocal().modules.find(
                                        (module) => module.module_name === 'Rules'
                                      ).permissions.add
                                    ) {
                                      setOpenRuleModel(true);
                                    } else {
                                      setValidationMsg('User does not have permission to add.');
                                      setOpenValidation(true);
                                    }
                                  }
                                }}
                              >
                                Create
                              </Button>
                              <Button
                                disabled={!(selectedGlobalRules.length === 1)}
                                variant="contained"
                                sx={groupBtnStyle}
                                style={groupBtnStyleBdr}
                                onClick={() => {
                                  if (
                                    userDataFromLocal().modules.some(
                                      (module) => module.module_name === 'Rules'
                                    )
                                  ) {
                                    if (
                                      userDataFromLocal().modules.find(
                                        (module) => module.module_name === 'Rules'
                                      ).permissions.edit
                                    ) {
                                      setPatchToggleSaveModal(true);
                                    } else {
                                      setValidationMsg('User does not have permission to edit.');
                                      setOpenValidation(true);
                                    }
                                  }
                                }}
                              >
                                Edit
                              </Button>

                              <Button
                                variant="contained"
                                sx={
                                  (groupBtnStyle,
                                  {
                                    backgroundColor: '#f10404',
                                    '&:hover': { backgroundColor: '#be0303!important' }
                                  })
                                }
                                style={groupBtnStyleBdr}
                                onClick={() => {
                                  if (
                                    userDataFromLocal().modules.some(
                                      (module) => module.module_name === 'Rules'
                                    )
                                  ) {
                                    if (
                                      userDataFromLocal().modules.find(
                                        (module) => module.module_name === 'Rules'
                                      ).permissions.delete
                                    ) {
                                      handleDeleteRules();
                                    } else {
                                      setValidationMsg('User does not have permission to delete.');
                                      setOpenValidation(true);
                                    }
                                  }
                                }}
                              >
                                Delete
                              </Button>
                            </ButtonGroup>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  {/* Hide Save Button */}
                  {/* <Button
                    sx={{
                      position: 'absolute',
                      right: '30px',
                      bottom: '30px',
                      zIndex: '1',
                      background: '#f5f8fc',
                      border: '1px solid #ddd',
                      color: 'black',
                      '&:hover': {
                        backgroundColor: '#0A386D',
                        color: 'white'
                      }
                    }}
                    onClick={() => saveMetadataChanges(currentmetaData)}
                  >
                    Saved
                  </Button> */}
                </Box>
              )}

              {/* <Button
                sx={{
                  position: 'absolute',
                  right: '30px',
                  bottom: '30px',
                  zIndex: 1,
                  background: '#f5f8fc',
                  border: '1px solid #ddd',
                  color: 'black',
                  '&:hover': { backgroundColor: '#0A386D', color: 'white' }
                }}
                onClick={() => saveMetadataChanges(currentmetaData)}
              >
                Saved
              </Button> */}
            </SwipeableDrawer>
          </Box>
        )}
        {/* Left bar show hide */}
        {tabValue === 0 || tabValue === 3 ? (
          <Box id="leftBarShow" className={hideSideBar}>
            <Box className="nodeVisualization" onClick={() => panelLeftSideBar('hideBehind')}>
              <RenderIcons
                className="top-minus-margin"
                icon={getIcon(DoubleArrowIcon, null, 20, 20)}
              />
            </Box>
            <Box className="close">
              <RenderIcons
                icon={getIcon(CloseIcon, null, 18, 18)}
                onClick={() => panelLeftSideBar('')}
              />
            </Box>

            <Box className="editorInfo">
              {/* Info of canvas/editor eg: Visualization name, Node counts, Edge count */}

              <Accordion className="accordT" expanded={expandedTrue === 'panel1'}>
                <AccordionSummary
                  className="accordTrigger"
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  classes={{ content: 'accordTgrtxt' }}
                >
                  <Typography className="typoAccord">Project</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordSummery">
                  {Object.entries(currentprojectData).length > 0 && (
                    <>
                      <Typography className="userVisual" variant="h2" mb={1}>
                        <RenderIcons icon={getIcon(UserIcon, null, 13, 13)} />
                        <span className="wordwrapbreak">
                          {currentprojectData.projects.project_name}
                        </span>
                        <Typography variant="body2" className="spanCustom">
                          #{experimentId}
                        </Typography>
                      </Typography>
                      <Divider />
                      {/* {JSON.stringify(currentprojectData)} */}
                      <Typography mt={1} mb={1}>
                        Created on{' '}
                        <Typography component="span" className="tagline">
                          {fDateTime(currentprojectData.projects.creation_date)}
                        </Typography>
                      </Typography>
                      <Typography>
                        Edited on{' '}
                        <Typography component="span" className="tagline">
                          {fDateTime(currentprojectData.projects.modified_date)}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion
                className="accordT"
                expanded={expanded === 'panel2'}
                onChange={handleChange('panel2')}
              >
                <AccordionSummary
                  className="accordTrigger"
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  classes={{ content: 'accordTgrtxt' }}
                >
                  <Typography className="typoAccord">
                    <Typography className="typoC-bound">
                      <RenderIcons
                        className="typoBadge"
                        icon={getIcon(groupChainIcon, null, 13, 13)}
                      />
                      Nodes
                    </Typography>
                    {cyToState !== null ? (
                      <Badge badgeContent={cyToState.nodes().length} max={9999} color="primary" />
                    ) : (
                      ''
                    )}
                  </Typography>
                </AccordionSummary>

                {/* ------------ Node Search Option ---- starts here ------------ */}
                {/* <Box className="nodeSearch">
                    <div className="search-box">
                      <TextField
                        variant="outlined"
                        placeholder="Search"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton>
                                <Search />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        sx={{ width: '100%', borderWidth: '0px' }}
                      />
                    </div>
                  </Box> */}
                <Box className="showAllNodesBlock">
                  {slectedOption === 'node' ? (
                    <AccordionDetails className="accordSummery" sx={{ paddingBottom: '0px' }}>
                      <Box>
                        {currentNodeid !== '' && Object.entries(currentNodeInfo).length && (
                          <Box>
                            <Typography className="userVisual" variant="h2" mb={1}>
                              <Spoke />
                              {currentNodeInfo.label}
                              <br />
                            </Typography>
                            <Typography variant="body2" className="spanCustom" mb={2}>
                              #{currentNodeInfo.id}
                            </Typography>
                            <Divider />
                            {/* <Divider />
                            <Box className="iconHolderNodes"> */}
                            {/* Icons for actions on model */}
                            {/* <Box className="icons-container">
                                <IconButton>
                                  <Visibility />
                                </IconButton>
                                <IconButton>
                                  <Edit />
                                </IconButton>
                                <IconButton>
                                  <Delete />
                                </IconButton>
                                <IconButton>
                                  <Refresh />
                                </IconButton>
                              </Box> */}
                            {/* </Box>
                            <Divider /> */}
                            <Divider />
                            {/* {JSON.stringify(currentprojectData)} */}
                            <Typography className="subHeadingNode">PROPERTIES</Typography>
                            {/* {Object.keys(currentNodeInfo.props).length &&
                              Object.keys(currentNodeInfo.props).map((prop, propIndex) => {
                                return (
                                  <Typography mt={1} mb={1} key={propIndex}>
                                    <strong>{camlecaseAndUnderScore(prop)}</strong>{' '}
                                    <Typography className="tagline">
                                      {Object.values(currentNodeInfo.props)[propIndex]}
                                    </Typography>
                                  </Typography>
                                );
                              })} */}
                            {currentNodeInfo?.props &&
                              Object.entries(currentNodeInfo.props).map(([prop, value]) => (
                                <Typography mt={1} mb={1} key={prop}>
                                  <strong>{camlecaseAndUnderScore(prop)}</strong> <br />
                                  <span style={{ paddingRight: '5px' }} className="tagline">
                                    {urlPattern.test(value) ? (
                                      <Button
                                        size="small"
                                        sx={{ mt: 1 }}
                                        onClick={() => handleOpenMediaDialog(value)}
                                        variant="contained"
                                      >
                                        Open Media
                                      </Button>
                                    ) : (
                                      value
                                    )}
                                  </span>
                                </Typography>
                              ))}

                            {/* Dialog for showing content */}
                            <Dialog
                              open={openMediaDialog}
                              onClose={handleCloseMediaDialog}
                              maxWidth="md"
                              fullWidth
                            >
                              <DialogTitle
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography variant="h6">Media Preview</Typography>
                                <IconButton
                                  size="small"
                                  onClick={handleCloseMediaDialog}
                                  // sx={{ position: 'absolute', right: 8, top: 8 }}
                                >
                                  <Close />
                                </IconButton>
                              </DialogTitle>
                              <DialogContent>
                                {mediaDialogContent !== '' && (
                                  <Box
                                    sx={{
                                      maxHeight: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {isImage(mediaDialogContent) ? (
                                      <img
                                        src={mediaDialogContent}
                                        alt="content"
                                        style={{
                                          maxHeight: '100%',
                                          objectFit: 'contain',
                                          objectPosition: 'center'
                                        }}
                                        // onError={(e) => (e.target.style.display = 'none')} // Optional error handling
                                      />
                                    ) : isVideo(mediaDialogContent) ? (
                                      <video controls style={{ width: '100%' }}>
                                        <source
                                          src={mediaDialogContent}
                                          type={`video/${getVideoType(mediaDialogContent)}`}
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                    ) : isAudio(mediaDialogContent) ? (
                                      <audio controls style={{ width: '100%' }}>
                                        <source
                                          src={mediaDialogContent}
                                          type={`audio/${getAudioType(mediaDialogContent)}`}
                                        />
                                        Your browser does not support the audio tag.
                                      </audio>
                                    ) : (
                                      <a
                                        href={mediaDialogContent}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {String(mediaDialogContent)}
                                      </a>
                                    )}
                                  </Box>
                                )}
                              </DialogContent>
                            </Dialog>
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  ) : (
                    // allNodeForInfo.length > 0 ? (
                    //   //  ---- shows node ids--------
                    //   allNodeForInfo.map((node, nodeIndex) => (
                    //     <Box className="nodeIdInfo" key={nodeIndex}>
                    //       {node.id}
                    //     </Box>
                    //   ))
                    // )
                    // : ('')
                    <Typography mt={1} mb={1} sx={{ padding: '5px 10px' }}>
                      <strong>Please select a Node</strong>
                    </Typography>
                  )}
                </Box>
              </Accordion>
              <Accordion
                className="accordT"
                expanded={expanded === 'panel3'}
                onChange={handleChange('panel3')}
              >
                <AccordionSummary
                  className="accordTrigger"
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  classes={{ content: 'accordTgrtxt' }}
                >
                  <Typography className="typoAccord">
                    <Typography className="typoC-bound">
                      <RenderIcons icon={getIcon(JourneyIcon, null, 12, 12)} />
                      Edges
                    </Typography>
                    {cyToState !== null ? (
                      <Badge
                        className="typoBadge"
                        badgeContent={cyToState.edges().length}
                        max={9999}
                        color="primary"
                      />
                    ) : (
                      ''
                    )}
                  </Typography>
                </AccordionSummary>
                <Box className="showAllNodesBlock">
                  {Object.keys(currentEdgeData).length > 0 ? (
                    <AccordionDetails className="accordSummery">
                      <Box>
                        <Divider />
                        <Divider />
                        {/* {JSON.stringify(currentprojectData)} */}
                        <Typography className="subHeadingNode">PROPERTIES</Typography>
                        {Object.keys(currentEdgeData.props).length &&
                          Object.keys(currentEdgeData.props).map((prop, propIndex) => {
                            return (
                              <Typography mt={1} mb={1} key={propIndex}>
                                <strong>{camlecaseAndUnderScore(prop)}</strong>{' '}
                                <Typography className="tagline">
                                  {Object.values(currentEdgeData.props)[propIndex]}
                                </Typography>
                              </Typography>
                            );
                          })}
                      </Box>
                    </AccordionDetails>
                  ) : (
                    <Typography mt={1} mb={1} sx={{ padding: '5px 10px' }}>
                      <strong>Please select an Edge</strong>
                    </Typography>
                  )}
                </Box>
              </Accordion>
              <Accordion
                className="accordT"
                expanded={expanded === 'panel5'}
                onChange={handleChange('panel5')}
              >
                <AccordionSummary
                  className="accordTrigger"
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                  classes={{ content: 'accordTgrtxt' }}
                >
                  <Typography className="typoAccord">
                    <Typography className="typoC-bound">
                      <RenderIcons icon={getIcon(ModelIcon, null, 12, 12)} />
                      Model
                    </Typography>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    className="accordInfo kGraphExportPge"
                    style={{ overflowY: 'auto', height: '50vh' }}
                  >
                    <KnowledgeGraphModelComp
                      cyToState={cyToState}
                      setRefreshKGComp={setRefreshKGComp}
                      projectName={
                        Object.entries(currentprojectData).length > 0 &&
                        currentprojectData.projects.project_name
                      }
                      compTyp="visual"
                      hideSomeIcons="hideSomeIcons"
                      modelSwitcher={setLoader}

                      //    modelCount={modelCount}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion
                  className="accordT"
                  expanded={expanded === 'panel4'}
                  onChange={handleChange('panel4')}
                >
                  <AccordionSummary
                    className="accordTrigger"
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                    classes={{ content: 'accordTgrtxt' }}
                  >
                    <Typography className="typoAccord">
                      <Typography className="typoC-bound">
                        <RenderIcons icon={getIcon(DataSourceIcon, null, 12, 12)} />
                        Datasource
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="datasourceOptions">
                    <Typography className="accordInfo">
                      {/* <KnowledgeGraphDataSource setResponseColoumData={setResponseColoumData} /> *
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  className="accordT"
                  expanded={expanded === 'panel5'}
                  onChange={handleChange('panel5')}
                >
                  <AccordionSummary
                    className="accordTrigger"
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                    classes={{ content: 'accordTgrtxt' }}
                  >
                    <Typography className="typoAccord">
                      <Typography className="typoC-bound">
                        <RenderIcons icon={getIcon(ModelIcon, null, 12, 12)} />
                        Model
                      </Typography>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="accordInfo">
                      {/* <KnowledgeGraphModel setResponseColoumData={setResponseColoumData} /> *
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
            </Box>
          </Box>
        ) : null}
        {/* {console.log('componentloading')} */}
        <>
          {/* {console.log('componentloading')} */}
          {(cytoData.length > 1 && tabValue === 0) || tabValue === 3 ? (
            <>
              {/* <LoaderOpaque setAllContentLoaded={setAllContentLoaded} loader={loader.mainLoader} /> */}
              <CytoscapeComponent
                key={modelId}
                ref={cytoscapeComponentRef}
                elements={displayCytoData}
                style={{
                  width: '100vw',
                  height: '100vh',
                  background: '#f8f8f8'
                }}
                stylesheet={graphStyling}
                clipboard={true}
                wheelSensitivity={0.05}
                zoom={1}
                // layout={{ name: 'cose' }}
                cy={(cy) => {
                  // console.log(cy);
                  setCyToState(cy);

                  dispatch({
                    type: 'CREATE_KG',
                    payload: {
                      key: 'cyToState',
                      value: cy
                    }
                  });
                }}
              />
              {tabValue !== 3 && labelToDisplay.length > 0 && labelEdgeToDisplay.length > 0 ? (
                <Box id="legendContainer" className="legendHolder-container">
                  <Button
                    style={{ borderRadius: 0 }}
                    onClick={handleLegendHolderToggle}
                    sx={{
                      width: '100%',
                      borderRadius: 0,
                      background: '#7b68ee'
                    }}
                    size="small"
                    variant="contained"
                    startIcon={
                      toggleLegendHolder ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowUp />
                    }
                    endIcon={
                      toggleLegendHolder ? <KeyboardDoubleArrowDown /> : <KeyboardDoubleArrowUp />
                    }
                  >
                    {toggleLegendHolder ? 'Collapse Legends' : 'Expand Legends'}
                  </Button>
                  <Box id="legendHolder" className="legendHolder">
                    {labelToDisplay.map((labelObj) => {
                      return (
                        <Box
                          key={labelObj.label}
                          sx={{ cursor: 'pointer' }}
                          onMouseOver={() => {
                            const _nodes = cyToState
                              .nodes()
                              .filter((item) => item.data().label === labelObj.label);
                            cyToState.elements().stop();
                            cyToState.elements().style({
                              'border-color': '',
                              'border-width': '',
                              'line-color': '',
                              'border-opacity': 0,
                              width: '',
                              height: ''
                            });
                            // _nodes.addClass('borderAnimate');
                            interval = setInterval(() => {
                              cyToState.elements().style({
                                'line-color': '',
                                width: '',
                                height: ''
                              });
                              _nodes.style({ width: 100, height: 100 });
                              _nodes.animate(
                                {
                                  style: {
                                    'border-color': 'red',
                                    'border-width': 12,
                                    'border-opacity': 1
                                  }
                                },
                                {
                                  duration: 250,
                                  complete: () => {
                                    _nodes.clearQueue();
                                    _nodes.animate(
                                      {
                                        style: {
                                          'border-color': 'pink',
                                          'border-width': 1,
                                          'border-opacity': 1
                                        }
                                      },
                                      {
                                        duration: 250,
                                        complete: () => {
                                          _nodes.clearQueue();
                                          _nodes.animate(
                                            {
                                              style: {
                                                'border-color': 'red',
                                                'border-width': 12,
                                                'border-opacity': 1
                                              }
                                            },
                                            {
                                              duration: 250,
                                              complete: () => {
                                                // cyToState.nodes().stop();
                                                // console.log('stop', interval);
                                                _nodes.clearQueue();
                                              }
                                            }
                                          );
                                        }
                                      }
                                    );
                                  }
                                }
                              );
                            }, 755);

                            // console.log('onclick node', _nodes);
                          }}
                          onMouseOut={() => {
                            const _nodes = cyToState
                              .nodes()
                              .filter((item) => item.data().label === labelObj.label);

                            // console.log('onMouseOut node', _nodes);
                            cyToState.elements().stop();
                            cyToState.elements().clearQueue();
                            cyToState.elements().style({
                              'border-color': '',
                              'border-width': '',
                              'line-color': '',
                              'border-opacity': 0,
                              width: '',
                              height: ''
                            });
                            clearInterval(interval);
                          }}
                          className="legendCart"
                        >
                          {labelObj['background-image'] ? (
                            <img
                              src={labelObj['background-image']}
                              alt=""
                              style={{
                                width: 35,
                                height: 35,
                                border: `3px solid ${labelObj['background-color']}`,
                                borderRadius: '50%',
                                display: 'felx',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            />
                          ) : (
                            <Avatar
                              sx={{
                                bgcolor: labelObj['background-color'],
                                width: 25,
                                height: 25,
                                display: 'felx',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <Workspaces style={{ width: 14, height: 14, color: 'black' }} />
                            </Avatar>
                          )}
                          &nbsp;{labelObj.label}
                        </Box>
                      );
                    })}
                    {labelEdgeToDisplay.map((labelObj) => (
                      <Box
                        sx={{ cursor: 'pointer' }}
                        onMouseOver={() => {
                          const _edges = cyToState
                            .edges()
                            .filter((item) => item.data().label === labelObj.label);

                          cyToState.elements().stop();
                          cyToState.elements().style({
                            'border-color': '',
                            'border-width': '',
                            'border-opacity': 0,
                            'line-color': '',
                            width: '',
                            height: ''
                          });

                          interval = setInterval(() => {
                            cyToState.elements().style({
                              'border-color': '',
                              'border-opacity': 0,
                              'border-width': ''
                            });
                            _edges.animate(
                              {
                                style: {
                                  'line-color': 'pink',
                                  width: 5
                                }
                              },
                              {
                                duration: 250,
                                complete: () => {
                                  _edges.clearQueue();
                                  _edges.animate(
                                    {
                                      style: {
                                        'line-color': 'deeppink',
                                        width: 5
                                      }
                                    },
                                    {
                                      duration: 250,
                                      complete: () => {
                                        _edges.clearQueue();
                                        _edges.animate(
                                          {
                                            style: {
                                              'line-color': 'pink',
                                              width: 5
                                            }
                                          },
                                          {
                                            duration: 250,
                                            complete: () => {
                                              // cyToState.nodes().stop();
                                              // console.log('stop', interval);
                                              _edges.clearQueue();
                                            }
                                          }
                                        );
                                      }
                                    }
                                  );
                                }
                              }
                            );
                          }, 755);

                          // console.log('onclick edge', _edges);
                        }}
                        onMouseOut={() => {
                          const _edges = cyToState
                            .edges()
                            .filter((item) => item.data().label === labelObj.label);

                          // console.log('onMouseOut edge', _edges);
                          cyToState.elements().stop();
                          cyToState.elements().clearQueue();
                          cyToState.elements().style({
                            'border-color': '',
                            'border-width': '',
                            'border-opacity': 0,
                            'line-color': '',
                            width: '',
                            height: ''
                          });
                          clearInterval(interval);
                        }}
                        className="legendCart"
                      >
                        {' '}
                        <Avatar
                          sx={{
                            bgcolor: labelObj['line-color'],
                            width: 25,
                            height: 25,
                            display: 'felx',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Timeline style={{ width: 14, height: 14, color: 'black' }} />
                        </Avatar>{' '}
                        {labelObj.label}
                      </Box>
                    ))}
                  </Box>
                </Box>
              ) : (
                ''
              )}

              <Snackbar
                key={snackbarToggle.message ? snackbarToggle.message : undefined}
                message={snackbarToggle.message}
                open={snackbarToggle.trigger}
                autoHideDuration={4000}
                TransitionComponent={(props) => SlideTransition(props)}
                onClose={handleCloseSnackbar}
                // TransitionProps={{ onExited: handleExited }}
                // message={messageInfo ? messageInfo.message : undefined}
                action={
                  <IconButton size="small" onClick={handleCloseSnackbar}>
                    <MuiCloseIcon fontSize="small" />
                  </IconButton>
                }
              />
              {/* <Box className="nodesSearchArea"> */}
              {/* <SearchNodesAndEdges ref={searchBarRef} /> */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={tabValue !== 3 ? { opacity: 1 } : { opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
              >
                {tabValue !== 3 && (
                  <OptionsPlate
                    currentprojectData={currentprojectData}
                    createNodesAndEdges={createNodesAndEdges}
                    graphStyling={graphStyling}
                    setStyling={setStyling}
                    setCytoData={setCytoData}
                    setCurrentprojectData={setCurrentprojectData}
                    resetGraphFunction={resetGraph}
                  />
                )}
              </motion.div>
            </>
          ) : (
            ''
          )}

          {tabValue === 1 ? (
            <Box sx={{ position: 'relative', width: '100vw', height: '100vh' }}>
              {cytoData.length > 1 ? (
                <>
                  {/* <CytoscapeComponent
                    elements={displayCytoData}
                    style={{ width: '100vw', height: '100vh', zIndex: 5000 }}
                    stylesheet={graphStyling}
                    wheelSensitivity={0.05}
                    zoom={1}
                    layout={{ name: 'cose' }}
                    cy={(cy) => {
                      console.log(cy);
                      setCyToState(cy);

                      dispatch({
                        type: 'CREATE_KG',
                        payload: {
                          key: 'cyToState',
                          value: cy
                        }
                      });
                    }}
                  /> */}
                  {/* <DataMap
                    setCyToState={setCyToState}
                    cyToState={cyToState}
                    displayCytoData={displayCytoData}
                    graphStyling={graphStyling}
                  /> */}
                  <BingMap setTabValue={setTabValue} cyToState={cyToState} />
                </>
              ) : (
                ''
              )}
            </Box>
          ) : (
            ''
          )}
          {/* {tabValue === 2 ? <CustomDataGrid /> : ''} */}
          {tabValue === 2 ? <MyDataGrid /> : ''}
          {tabValue === 3 ? (
            <motion.div
              style={{ position: 'fixed', bottom: 0, left: 0, height: 250, width: '100vw' }}
              animate={{
                scale: 1
              }}
              initial={{
                scale: 0
              }}
              transition={{ duration: 1 }}
            >
              <Box
                sx={{
                  position: 'fixed',
                  top: 10,
                  right: 10,
                  height: 50,
                  width: 50,
                  zIndex: 100
                }}
              >
                <Tooltip title="Exit Timeline">
                  <IconButton onClick={handleExitTimeline}>
                    <ExitToApp color="error" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TimelineGraph
                // modelId={modelId}
                cytoscapeComponentRef={cytoscapeComponentRef}
                displayCytoData={displayCytoData}
                graphStyling={graphStyling}
                setCyToState={setCyToState}
                handleExitTimeline={handleExitTimeline}
              />
            </motion.div>
          ) : (
            ''
          )}
          {tabValue !== 3 && (
            <BottomTabs
              // setSelectedGraphTabValue={setSelectedGraphTabValue}
              callTabValue={tabValue}
              callSetTabValue={setTabValue}
              // callSubTabValue={subTabValue}
              // callSetSubTabValue={setSubTabValue}
              // cyToStateRun={cyToState}
            />
          )}
          {/* <SearchListing currentprojectData={currentprojectData} /> */}
        </>
      </Container>
    </Page>
  );
}
export default Visualization;

function RuleEngine() {
  const [age, setAge] = React.useState('');
  const [arr, setArray] = useState([]);
  const [num, setNum] = useState(0);
  const [mutate, setMutate] = useState();
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const AddRuleHandler = (e) => {
    const ruleArray = arr;
    const num1 = num;
    setNum(num1 + 1);
    ruleArray.push(num);
    // ruleArray.forEach((item) => {
    //   num += 1;
    // });
    setArray(ruleArray);
    setMutate(new Date());
  };
  // console.log('ruleArray1:', arr);
  return (
    <>
      <Box className="ruleEngine_HeaderBox">
        <Box className="ruleEngine_HeaderBox_Main">
          <FormControl sx={{ m: 1, minWidth: 50 }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="and">And</MenuItem>
              <MenuItem value="or">Or</MenuItem>
            </Select>
          </FormControl>
          <Button small variant="outlined" onClick={(e) => AddRuleHandler(e)}>
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">RULE</span>
            </Typography>
          </Button>
          <Button small variant="outlined">
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">GROUP</span>
            </Typography>
          </Button>
        </Box>
        <Box className="ruleEngine_HeaderBox_Main">
          <FormControl sx={{ m: 1, minWidth: 50 }}>
            <Select
              value={age}
              onChange={(e) => handleChange(e)}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="and">And</MenuItem>
              <MenuItem value="or">Or</MenuItem>
            </Select>
          </FormControl>
          <Button small variant="outlined" onClick={(e) => AddRuleHandler(e)}>
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">RULE</span>
            </Typography>
          </Button>
          <Button small variant="outlined">
            <Typography className="typo">
              <span className="spanIcon">+</span>
              <span className="spantext">GROUP</span>
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box className="ruleEngine_FooterBox">
        <Button small variant="outlined">
          Close
        </Button>
        <Button small variant="outlined">
          Ok
        </Button>
        <Button small variant="outlined">
          Filter
        </Button>
      </Box>
    </>
  );
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function createNodesAndEdges(elementsData) {
  console.log('finalelementsData', elementsData);
  const graphDataArray = [];
  elementsData.data.forEach((item, index) => {
    // console.log('graphitem', item);
    const newIdOne = `${item.node1.label.replace(/\s/g, '')}-${
      item.node1.labelType
        ? String(item.node1.labelType).replace(/\s/g, '')
        : Date.parse(new Date())
    }`;
    const newIdTwo = `${item.node2.label.replace(/\s/g, '')}-${
      item.node2.labelType
        ? String(item.node2.labelType).replace(/\s/g, '')
        : Date.parse(new Date())
    }`;
    // console.log('newIdnewId', newIdOne);
    const node1 = {
      data: {
        id: item.node1.properties.node_id,
        label: item.node1.label,
        labelType: item.node1.labelType,
        props: { ...item.node1.properties }
        // index === 13 || index === 39 || index === 54 || index === 90 || index === 179
      },
      group: 'nodes',
      id: newIdOne
    };
    const node2 = {
      data: {
        id: item.node2.properties.node_id,
        label: item.node2.label,
        labelType: item.node2.labelType,
        props: { ...item.node2.properties }
        // isBlacklisted: true // index === 13 || index === 39 || index === 54 || index === 90 || index === 179
      },
      group: 'nodes',
      id: newIdTwo
    };
    const relation = {
      data: {
        source: item.node1.properties.node_id,
        target: item.node2.properties.node_id,
        label: item.relationship.name,
        props: item.relationship.properties
      },
      group: 'edges'
    };
    graphDataArray.push(node1);
    graphDataArray.push(node2);
    graphDataArray.push(relation);
  });
  // console.log('graphDataArray', graphDataArray);
  return graphDataArray;
}

function generateId(elementsData, nodes) {}
function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
